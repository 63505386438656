import { VerificationStatus, CheckStatus } from '@api';
import { IBubbleItem } from '@components';
import { ITimeOptionInfo, IVerificationCheck } from './types';



export const APP_TITLE = 'Human Verification';



export const TIME_OPTIONS: ITimeOptionInfo[] = [
  {
    key: '15m',
    title: 'Within 15 minutes',
  },
  {
    key: '6h',
    title: 'Within 6 hours',
  },
  {
    key: '2d',
    title: 'Within 2 days',
  },
];



export const VERIFICATION_CHECKS: IVerificationCheck[] = [
  {
    title: 'Official document ID verification',
    timeOptions: ['15m', '6h', '2d'],
  },
  {
    title: 'Residential history',
    timeOptions: ['15m', '6h', '2d'],
  },
  {
    title: 'Criminal history & sex offender registries',
    timeOptions: ['15m', '6h', '2d'],
  },
  {
    title: 'Employment & education history',
    timeOptions: ['6h', '2d'],
  },
  {
    title: 'Financial records & credit scores',
    timeOptions: ['6h', '2d'],
  },
  {
    title: 'Military records',
    timeOptions: ['2d'],
  },
];



export const PERSON_STATUS_FILTERS: IBubbleItem[] = [
  {
    key: '',
    title: 'All',
  },
  {
    key: VerificationStatus.New,
    title: 'New',
  },
  {
    key: VerificationStatus.Assigned,
    title: 'Assigned',
  },
  {
    key: VerificationStatus.Finished,
    title: 'Finished',
  },
  {
    key: VerificationStatus.Canceled,
    title: 'Canceled',
  },
  {
    key: VerificationStatus.TimeoutExceeded,
    title: 'Timeout Exceeded',
  },
  {
    key: VerificationStatus.Error,
    title: 'Error',
  },
];



export const VERIFICATION_STATUS_TITLES: Record<
  VerificationStatus,
  { short: string, long: string }
> = {
  'New': {
    short: 'New',
    long: 'New verification',
  },
  'Assigned': {
    short: 'Assigned',
    long: 'Verification in progress',
  },
  'Canceled': {
    short: 'Canceled',
    long: 'Verification canceled',
  },
  'TimeoutExceeded': {
    short: 'Timeout',
    long: 'Verification timeout exceeded',
  },
  'Error': {
    short: 'Error',
    long: 'Verification error',
  },
  'Finished': {
    short: 'Finished',
    long: 'Verification complete',
  },
};



export const CHECK_STATUS_TITLES: Record<
  CheckStatus,
  string
> = {
  'in-queue': 'In Queue',
  'pending': 'Pending',
  'complete': 'Complete',
  'skipped': 'Skipped',
};



export const RISK_LEVEL_INFO = [
  {
    name: 'Low risk',
    color: '#00D700',
  },
  {
    name: 'Medium risk',
    color: '#FFD700',
  },
  {
    name: 'Medium-High risk',
    color: '#F39D37',
  },
  {
    name: 'High risk',
    color: '#F30037',
  },
];
