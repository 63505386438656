export interface IUser {
  //
}



export interface IPerson {
  firstName?: string
  lastName?: string
  photo?: string
  id?: string
}



export type RiskLevel = 0 | 1 | 2 | 3



export interface ISuspiciousItem {
  riskLevel: RiskLevel
  title: string
}



export interface ITimelineStep {
  person?: IPerson
  date?: Date
  finished: boolean
}



export interface ITimeline {
  submitted?: ITimelineStep
  processed?: ITimelineStep
  completed?: ITimelineStep
}



export type CheckStatus = 'in-queue' | 'pending' | 'complete' | 'skipped'

export interface ICheck {
  id: string

  title: string
  shortTitle?: string
  status: CheckStatus
  riskLevel?: RiskLevel

  suspicious?: ISuspiciousItem[]
  timeline: ITimeline,
}



// export type VerificationStatus = 'in-progress' | 'failed' | 'done'
export enum VerificationStatus {
  New = 'New',
  Assigned = 'Assigned',
  Finished = 'Finished',
  TimeoutExceeded = 'TimeoutExceeded',
  Canceled = 'Canceled',
  Error = 'Error',
}

export interface IVerification {
  // id: string
  // person: IPerson
  // status: VerificationStatus

  // generalRiskCheck: ICheck
  // checks: ICheck[]

  id: string
  createdTime: string
  fields: {
    [key: string]: unknown

    'First name': string
    'ID': string
    'Last name': string
    'Search data': string
    'Verification id': string
    'Verification status': VerificationStatus
    'Verification submitted timestamp': string
    'Verification results html': string
  }
}



export interface IFindPersonResult {
  id: string,
  createdTime: string,
  fields: Record<string, string | number>,
}
