import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgSingleColorProps, SvgSizeProps } from './props';



export const BackIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 10,
  height = 18,
  color = '#2A2A2A',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 10 18" fill="none">
    <Path
      d="M9 1L1 9L9 17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>;
};

export const CloseIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 36,
  height = 36,
  color = '#4A4A4A',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 36 36" fill="none">
    <Path d="M27 9L9 27" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M9 9L27 27" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>;
};
