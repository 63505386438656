import { Box, ChevronRightIcon, HStack, IBoxProps, ITextProps, Pressable, Text } from 'native-base';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Animated, StyleSheet } from 'react-native';



interface IProps extends IBoxProps {
  leftIcon?: React.ReactElement,
  arrowIcon?: React.ReactElement,
  title: string,
  subtitle?: string,
  collapsedHeight?: number,
  minExpandedHeight?: number,
  separatorWidth?: number,
  separatorColor?: string,
  contentContainerProps?: IBoxProps,
  titleProps?: ITextProps,
  subtitleProps?: ITextProps,
  disabled?: boolean,
}

export const ExpandableCard: React.FC<IProps> = ({
  leftIcon,
  arrowIcon = <ChevronRightIcon />,
  title,
  subtitle,
  children,
  collapsedHeight = 16,
  minExpandedHeight = 0,
  separatorWidth = 1,
  separatorColor,
  contentContainerProps,
  titleProps,
  subtitleProps,
  disabled,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(minExpandedHeight);
  const [isAnimating, setIsAnimating] = useState(false);

  const collapsedSize = 0;
  const expandedSize = contentHeight;

  const animatedContentSize = useRef(new Animated.Value(collapsedSize)).current;
  const animatedArrowRotation = useRef(new Animated.Value(0)).current;
  const animatedSeparatorOpacity = useRef(new Animated.Value(0)).current;

  const toggle = useCallback(() => {
    setIsExpanded(!isExpanded);
    setIsAnimating(true);
  }, [isExpanded]);

  const layoutContent = useCallback((event) => {
    const height = Math.ceil(event.nativeEvent.layout.height);
    setContentHeight((contentHeight) => Math.max(contentHeight, height));
  }, []);

  const arrowDynamicStyle = useMemo(() => {
    const interpolateRotating = animatedArrowRotation.interpolate({
      inputRange: [0, 90],
      outputRange: ['0deg', '90deg'],
    });
    return {
      transform: [{ rotate: interpolateRotating }],
    };
  }, [animatedArrowRotation]);

  const contentDynamicStyle = useMemo(
    () => ({
      height: animatedContentSize,
    }),
    [animatedContentSize]
  );

  const separatorDynamicStyle = useMemo(
    () => ({
      opacity: animatedSeparatorOpacity,
    }),
    [animatedSeparatorOpacity]
  );

  useEffect(() => {
    animatedContentSize.stopAnimation();
    animatedArrowRotation.stopAnimation();
    animatedSeparatorOpacity.stopAnimation();

    Animated.parallel([
      Animated.timing(animatedContentSize, {
        toValue: isExpanded ? expandedSize : collapsedSize,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.timing(animatedArrowRotation, {
        toValue: isExpanded ? 90 : 0,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.timing(animatedSeparatorOpacity, {
        toValue: isExpanded ? 1 : 0,
        duration: 200,
        useNativeDriver: false,
      }),
    ]).start(() => setIsAnimating(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedSize, isExpanded]);

  return (
    <Box {...props}>
      <Pressable onPress={toggle} disabled={disabled}>
        <HStack
          alignItems="center"
          h={collapsedHeight}
        >
          {leftIcon}
          <Text
            flex="1"
            flexShrink="1"
            numberOfLines={1}
            children={title}
            {...titleProps}
          />
          {subtitle &&
            <Text
              numberOfLines={1}
              children={subtitle}
              {...subtitleProps}
            />
          }
          <Animated.View style={arrowDynamicStyle}>
            {arrowIcon}
          </Animated.View>
        </HStack>
      </Pressable>

      {separatorColor &&
        <Animated.View
          style={[
            styles.separator,
            {
              height: separatorWidth,
              backgroundColor: separatorColor,
            },
            separatorDynamicStyle,
          ]}
        />
      }

      <Animated.View
        style={[styles.collapsableContainer, contentDynamicStyle]}
        removeClippedSubviews={false}
      >
        <Box
          onLayout={layoutContent}
          removeClippedSubviews={false}
          position="absolute"
          top="0"
          left="0"
          right="0"
          {...contentContainerProps}
        >
          {(isExpanded || isAnimating) && children}
        </Box>
      </Animated.View>
    </Box>
  );
};



const styles = StyleSheet.create({
  separator: {
    width: '100%',
  },
  collapsableContainer: {
    overflow: 'hidden',
  },
});
