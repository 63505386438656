import AsyncStorage from '@react-native-async-storage/async-storage';



class SecureStorage {
  formatKey(key: string): string {
    return key.replace(/@|:/g, '_');
  }



  getItem(key: string): Promise<string | null> {
    return AsyncStorage.getItem(this.formatKey(key));
  }

  setItem(key: string, value: string): Promise<void> {
    return AsyncStorage.setItem(this.formatKey(key), value);
  }

  removeItem(key: string): Promise<void> {
    return AsyncStorage.removeItem(this.formatKey(key));
  }
}



let instance: SecureStorage | undefined;
export default function secureStorage(): SecureStorage {
  if (instance === undefined) {
    instance = new SecureStorage();
  }

  return instance;
}
