import React from 'react';
import { useMemo } from 'react';
import { Image, ImageProps, ImageSourcePropType } from 'react-native';



export default function loadSvgFile(
  xml: ImageSourcePropType,
  originalWidth?: number,
  originalHeight?: number
): React.FC<ImageProps> {
  return (props) => {
    const composedProps = useMemo<ImageProps>(() => ({
      style: [
        {
          ...(originalWidth ? { width: originalWidth } : {}),
          ...(originalHeight ? { height: originalHeight } : {}),
        },
        props.style,
      ],
      resizeMode: 'contain',
      ...props,
    }), [props]);

    return <Image
      {...composedProps}
      source={xml}
    />;
  };
}
