import { extendTheme } from 'native-base';



// Define the config
const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const components = {
  Pressable: {
    baseStyle: {
      _pressed: {
        opacity: 0.5,
      },
    },
  },
  Button: {
    variants: {
      plain: () => ({
        _text: {
          fontWeight: '400',
          fontSize: 14,
        },
      }),
      link: () => ({
        _text: {
          color: 'lightGrey',
        },
      }),
      solid: () => ({
        bg: 'primary.100',
        _text: {
          color: 'white',
        },
      }),
      secondary: () => ({
        bg: 'secondary.100',
        _text: {
          color: 'dark',
        },
      }),
    },
  },
  Heading: {
    baseStyle: {
      color: 'dark',
    },
  },
  Text: {
    baseStyle: {
      color: 'dark',
    },
  },
  Input: {
    // NOTE: baseStyle doesn't work for Input in some reason...
    defaultProps: {
      fontWeight: '400',
      fontSize: 16,
      borderColor: 'extraLightGrey',
      borderWidth: 1,
      height: 13,
      placeholderTextColor: 'darkGrey',
    },
    variants: {
      empty: () => ({
        fontStyle: 'italic',
      }),
    },
  },
  InputLeftAddon: {
    baseStyle: {
      borderColor: 'extraLightGrey',
      _text: {
        color: 'dark',
        fontSize: 16,
        fontWeight: '700',
      },
    },
  },
};

const colors = {
  primary: {
    100: '#3051FF',
  },
  secondary: {
    100: '#FFD700',
  },
  bgSoft: '#fcfcfc',
  bgClear: '#fff',
  dark: '#2a2a2a',
  darkGrey: '#4A4A4A',
  grey: '#6D6D6D',
  lightGrey: '#B9B9B9',
  lightGrey2: '#C4C4C4',
  extraLightGrey: '#EFEFEF',
  extraLightGrey2: '#F4F4F4',
  green: '#C6FFD2',
};

const fontConfig = {
  SourceSansPro: {
    400: {
      normal: 'SourceSansPro-Regular',
    },
  },
  PTSans: {
    400: {
      normal: 'PTSans-Regular',
      italic: 'PTSans-Italic',
    },
    700: {
      normal: 'PTSans-Bold',
      italic: 'PTSans-BoldItalic',
    },
  },
};

const fonts = {
  tabs: 'SourceSansPro',
  body: 'PTSans',
};

const sizes = {
  13: 52,
  14: 56,
  18: 72,
  36: 144,
};

const fontSizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
};



// extend the theme
export const theme = extendTheme({ config, components, colors, fontConfig, fonts, sizes, fontSizes });

type AppThemeType = typeof theme;
declare module 'native-base' {
  interface ICustomTheme extends AppThemeType { }
}
