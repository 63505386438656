import React, { useCallback, useState } from 'react';
import {
  Box,
  Heading,
  VStack,
} from 'native-base';
import { ScreenContainer, FormTextInput, Loader } from '@components';
import { AppRoutes } from '@navigation/AppRoutes';
import { StackScreenProps } from '@react-navigation/stack';
import { FormProvider, useForm } from 'react-hook-form';
import { VerifyFooter } from '@components';
import { isValidEmail } from '@utils/validation';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';
import { IFindPersonInfo } from '@global/types';
import { handleAsyncOperation } from '@utils/promise';
import { FindPersonNavigatorParamsList } from '@navigation/FindPersonNavigator';



type INavProps = StackScreenProps<FindPersonNavigatorParamsList, AppRoutes.FindInputStep>;

export const FindInputStep: React.FC<INavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();

  const form = useForm<IFindPersonInfo>();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(() => {
    dispatch.verification.setFindPersonInfo(form.getValues());

    handleAsyncOperation(
      dispatch.verification.findPerson(undefined),
      setIsLoading,
      () => navigation.navigate(AppRoutes.FindResultsStep),
    );
  }, [dispatch.verification, form, navigation]);

  return (
    <Box flex="1" bg="bgSoft" px="6">
      <ScreenContainer
        keyboardAvoiding
        keyboardVerticalOffset={100}
      >
        <Heading size="lg" pt="2" pb="8">Step 1: Enter Contact Info</Heading>

        <FormProvider {...form}>
          <VStack space="6" mb="3">

            <FormTextInput
              name="email"
              rules={{
                required: true,
                validate: (value) => isValidEmail(value),
              }}
              placeholder="Email"
              keyboardType="email-address"
              autoCapitalize="none"
            />

          </VStack>
        </FormProvider>

        {isLoading &&
          <Loader mt="10" />
        }

      </ScreenContainer>

      <VerifyFooter
        forwardTitle="Find volunteer by email →"
        onForwardPress={form.handleSubmit(onSubmit)}
      />
    </Box>
  );
};
