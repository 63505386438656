import { Box, HStack } from 'native-base';
import React from 'react';



type PageIndicatorProps = {
  count: number,
  selectedIndex: number,
}

export const PageIndicator: React.FC<PageIndicatorProps> = ({ count, selectedIndex }) => {
  const dotProps = {
    on: {
      bg: 'grey',
    },
    off: {
      bg: 'lightGrey2',
    },
  };

  return (
    <HStack space="2" alignItems="center">
      {new Array(count).fill(0).map((_, index) =>
        <Box
          key={`dot_${index}`} w="2" h="2" rounded="full"
          {...dotProps[index === selectedIndex ? 'on' : 'off']}
        />
      )}
    </HStack>
  );
};
