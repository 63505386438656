import moment from 'moment';
import { AlertButton } from 'react-native';
import { Error } from './error';
import { Alert } from './Alert';



// Don't show same errors
const errors: {
    message: string,
    date: moment.Moment,
}[] = [];

export function showMessage(
  title: string,
  message?: string,
  onConfirm: (() => void) | undefined = undefined,
  onCancel: (() => void) | undefined = undefined,
  confirmTitle = 'OK',
  cancelTitle: string | undefined = undefined,
  confirmStyle: 'default' | 'cancel' | 'destructive' | undefined = 'cancel',
  cancelStyle: 'default' | 'cancel' | 'destructive' | undefined = 'default'
): void {
  if (message) {
    const date = moment();
    const existError = errors.find((e) => e.message === message && date.diff(e.date, 'second') <= 2);
    const needShowMessage = existError === undefined;
    if (!needShowMessage) {
      errors.push({ message, date });
      return;
    }
  }

  const array: AlertButton[] = [];

  if (cancelTitle) {
    array.push({
      text: cancelTitle,
      onPress: () => {
        if (onCancel !== undefined) {
          onCancel();
        }
      },
      style: confirmStyle,
    });
  }

  if (confirmTitle) {
    array.push({
      text: confirmTitle,
      onPress: () => {
        if (onConfirm !== undefined) {
          onConfirm();
        }
      },
      style: cancelStyle,
    });
  }

  Alert.alert(
    title,
    message,
    array,
    {
      cancelable: false,
    },
  );
}



export function showError(error: Error | string, onConfirm: (() => void) | undefined = undefined): void {
  const title = 'Error';
  const message = (typeof error === 'string') ? error : (error as Error).message;

  showMessage(title, message, onConfirm);
}

