import { isValidPhone } from '@utils/validation';
import moment from 'moment';
import { ITableItemsList, ValueTransformer } from './types';



export function buildDetailsPage(content: string, styles = '') {
  return `
    <html>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <head>
        <style>
          html, body {
            max-width: 100%;
            overflow-x: hidden;
          }
          body {
            color: #2a2a2a;
            font-family: "Arial";
            overflow-wrap: anywhere;
          }
          h2 {
            text-align: center;
          }
          table {
            width: 100%;
            border-spacing: 0px;
            border: 1px dashed grey;
          }
          td, th {
            background-color: #F8F9F9;
            border: 2px solid white;
          }

          img {
            width: 100%;
          }

          .noData {
            color: red;
          }
          table.titleValue th {
            width: 40%;
            text-align: right;
            padding-right: 10px;
          }
          table.titleValue .center {
            text-align: center;
            padding: 10 0 10 0;
            text-decoration: underline;
          }

          .danger {
            color: red;
            font-weight: bold;
          }
          .subvalue {
            font-style: italic;
            font-size: small;
          }

          ${styles}
        </style>
      </head>
      <body>
      ${content}
      </body>
    </html>
  `;
}



export function buildTableItemsFromObect<T>(
  data: Record<string, T>,
  fnVal: (v: T) => string,
  valueTransformers: ValueTransformer[] | undefined = undefined,
  fnKey: (k: string) => string = k => k,
): ITableItemsList {
  return Object.entries(data).map(([key, value]) => ({
    title: fnKey(key),
    value: fnVal(value),
    valueTransformers,
  })) as ITableItemsList;
}



export function buildTableItemsFromStringObect(
  data: Record<string, string | number>,
  valueTransformers: ValueTransformer[] | undefined = undefined,
  fnKey: (k: string) => string = k => k,
): ITableItemsList {
  return buildTableItemsFromObect(data, v => v.toString(), valueTransformers, fnKey);
}



export function buildTitleValueTable(data: ITableItemsList): string {
  return `
    <table class="titleValue">
    ${data.map(row => {
    if (!row) return '';

    // Only title
    if (row.value === undefined) {
      return `<tr><th class="center" colspan=2>${row.title}</th></tr>`;
    }

    // Transform value
    let value = row.value;
    for (const t of row.valueTransformers ?? []) {
      value = t(value);
    }

    return `<tr><th>${row.title}</th><td>${value}</td></tr>`;
  }).join('')}
    </table>
    `;
}



export function formatWorkTimeRange(
  startDate: number | string | undefined,
  endDate: number | string | undefined
): string {
  if (typeof startDate === 'number') startDate *= 1000;
  if (typeof endDate === 'number') endDate *= 1000;
  const start = startDate ? moment(startDate).format('MMM YYYY') : 'unknown';
  const end = endDate ? moment(endDate).format('MMM YYYY') : 'now';
  return `${start} - ${end}`;
}



export function formatPhoto(url: string): string {
  return `<a href="${url}" target="_blank"><img src="${url}"/></a>`;
}



export function successValueTransformer(value: string, isPhone: boolean): string {
  if (value.toLowerCase() === 'false') {
    return isPhone ?
      'unable to find phone number' :
      'unable to find social ID';
  }
  return value;
}



export function highlightLevel(value: string): string {
  if (value.toLowerCase() === 'high') {
    return `<span class="danger">${value}</span>`;
  }
  return value;
}



export function formatLinks(value: string): string {
  const lcValue = value.toLowerCase();
  if (lcValue.startsWith('http://') || lcValue.startsWith('https://')) {
    return `<a href="${value}" target="_blank">${value}</a>`;
  }
  return value;
}



export function formatPhoneNumbers(value: string): string {
  if (isValidPhone(value)) {
    return `<a href="tel:${value}">${value}</a>`;
  }
  return value;
}
