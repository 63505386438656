import React, { useCallback } from 'react';
import { FlatList, StyledProps } from 'native-base';
import { IVerification } from '@api';
import { VerificationItem } from './Verificationtem';
import { Loader } from '@components';

interface IProps extends StyledProps {
  data?: IVerification[]
  footer?: React.ReactElement,
  onSelectItem?: (item: IVerification) => void
  onRefresh?: (() => void) | null
  refreshing?: boolean | null
  isLoading?: boolean
}

export const VerificarionsList: React.FC<IProps> = ({
  data,
  footer,
  onSelectItem,
  isLoading,
  ...props
}) => {
  const renderItem = useCallback(({ item }: { item: IVerification }) => {
    return (
      <VerificationItem
        item={item}
        onPress={onSelectItem}
      />
    );
  }, [onSelectItem]);

  const keyExtractor = useCallback((item) => item.id, []);

  return (
    isLoading
      ?
      <Loader flex="1" />
      :
      <FlatList
        data={data}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={footer}
        {...props}
      />
  );
};
