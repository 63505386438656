import axios, {
  CancelToken, AxiosInstance,
  AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { Error } from '@utils/error';
import secureStorage from '@utils/secureStorage';
import moment from 'moment';
import EventEmitter from 'events';
import { scheduleAtTime } from '../utils/helpers';
import { Request } from './request';



interface RequestConfig extends AxiosRequestConfig {
  noSession?: boolean,
  useFormData?: boolean,
  dontCheckMessageError?: boolean,
}

export const CLIENT_EVENT_SESSION_EXPIRED = 'sessionExpired';

class Client {

  static authErrorCodes = [401];

  static storageKeySessionToken = 'solu_sessionToken';
  static storageKeySessionExpiration = 'solu_sessionExpiration';

  static logLevel = {
    none: 'NONE',
    short: 'SHORT',
    full: 'FULL',
  };

  sessionToken?: string;
  sessionExpiration?: string;

  sessionRenewTimeout?: NodeJS.Timeout;

  client: AxiosInstance;

  eventEmitter: EventEmitter;

  logLevel: string = __DEV__ ? Client.logLevel.full : Client.logLevel.none;

  constructor(baseURL: string, apiKey: string, cancelToken: CancelToken) {
    this.eventEmitter = new EventEmitter();

    this.client = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      maxContentLength: 20971520,
      baseURL,
      cancelToken,
      validateStatus: function (status) {
        return (status >= 200 && status <= 500); // || Client.authErrorCodes.some(s => s == status)
      },
    });

    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const noSession = (config as RequestConfig).noSession;
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${noSession ? apiKey : this.sessionToken}`,
      };

      return config;
    });

    this.client.interceptors.response.use(response => {
      this.logResponse(response);
      return response;
    });
  }



  async req<T = unknown>(config: RequestConfig): Promise<AxiosResponse<T>> {
    const response: AxiosResponse = await this.client(config);

    const result = await this.checkResponseData(response, config);

    if (result) {
      // Response is ok - return it
      return response;
    }
    else {
      // Session renewed - retry request
      return this.req(config);
    }
  }


  // true - return response as is
  // false - retry with new session
  // error - error happened
  async checkResponseData(
    response: AxiosResponse,
    requestConfig: RequestConfig,
  ): Promise<boolean> {

    // Token is expired
    if (//true ||
      this.sessionToken && this.sessionExpiration && !requestConfig.noSession &&
      this.isTokenExpired(response.data, response.status)
    ) {
      await this.resetSession();
      return true;
    }

    // Check error
    const error = this.isResponseError(
      response.data,
      response.status,
      response.statusText,
      requestConfig,
    );

    if (error) {
      console.log(error.message);
      throw error;
    }

    return true;
  }



  async resetSession() {
    this.sessionToken = undefined;
    this.sessionExpiration = undefined;

    await this.storeSession();
    this.eventEmitter.emit(CLIENT_EVENT_SESSION_EXPIRED);
  }




  isTokenExpired(
    _response: Record<string, unknown>,
    status: number | string | undefined = undefined,
  ): boolean {
    return Client.authErrorCodes.some(s => s === status);
  }



  setSession(sessionToken: string | undefined, sessionExpiration: string | undefined) {
    this.sessionToken = sessionToken;
    this.sessionExpiration = sessionExpiration;

    this.startRenewTimeout();
  }


  async storeSession() {
    if (this.sessionToken && this.sessionExpiration) {
      await secureStorage().setItem(Client.storageKeySessionToken, this.sessionToken);
      await secureStorage().setItem(Client.storageKeySessionExpiration, this.sessionExpiration);
    }
    else {
      await secureStorage().removeItem(Client.storageKeySessionToken);
      await secureStorage().removeItem(Client.storageKeySessionExpiration);
    }
  }


  async restoreSession() {
    const sessionToken = await secureStorage().getItem(Client.storageKeySessionToken) || undefined;
    const sessionExpiration = await secureStorage().getItem(Client.storageKeySessionExpiration) || undefined;

    if (__DEV__) {
      console.log(`API KEY: ${sessionToken} (expiration: ${sessionExpiration})`);
    }

    this.setSession(sessionToken, sessionExpiration);
  }


  isAuthorized(): boolean {
    if (!this.sessionToken || !this.sessionExpiration) return false;

    const now = moment();
    const tokenDate = moment.utc(this.sessionExpiration);
    if (tokenDate.isBefore(now)) return false;

    return true;
  }



  startRenewTimeout() {
    this.stopRenewTimeout();

    if (!this.sessionToken || !this.sessionExpiration) return;

    const mRenewDate = moment.utc(this.sessionExpiration).subtract({ minute: 1 });
    const renewDate = mRenewDate.toDate();
    console.log(`Session renewing scheduled at ${renewDate}`);

    this.sessionRenewTimeout = scheduleAtTime(renewDate, () => {
      Request.authTokenRefresh(this.sessionToken!, this.sessionExpiration!)
        .then(response => {
          this.setSession(response.data.token, response.data.expiration);
          return this.storeSession();
        })
        .catch(() => {
          this.resetSession().catch(console.log);
        });
    });
  }



  stopRenewTimeout() {
    if (!this.sessionRenewTimeout) return;

    clearInterval(this.sessionRenewTimeout);
    this.sessionRenewTimeout = undefined;
  }


  isResponseError(
    responseData: Record<string, unknown>,
    status: number,
    statusText: string | undefined = undefined,
    requestConfig: RequestConfig,
  ): Error | undefined {
    // Check HTTP error
    if (status < 200 || status > 299) {
      let message: string | undefined;

      if (typeof responseData === 'string') {
        message = responseData;
      }
      else if (typeof responseData?.message === 'string') {
        message = responseData?.message;
      }

      return {
        message: message || statusText || 'Something went wrong :(',
        code: status,
      };
    }

    // Check error, with status 200
    else {
      if (
        !requestConfig.dontCheckMessageError &&
        typeof responseData?.message === 'string' &&
        responseData?.message !== 'ok'
      ) {
        return {
          message: responseData?.message || statusText || 'Something went wrong :(',
          code: status,
        };
      }
    }

    return;
  }


  logResponse(axResponse: AxiosResponse): void {
    const config = axResponse.config;

    let requestData = {};
    if (config.data) {
      try {
        if (typeof config.data === 'string') {
          requestData = config.data;
        }
        else if (config.data instanceof FormData) {
          requestData = JSON.stringify(config.data);
        }
        else {
          requestData = JSON.parse(config.data);
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    if (this.logLevel === Client.logLevel.short && config.method) {
      console.log('========== REQUEST ==========\n' +
        `${config.method.toUpperCase()} ${config.url}\n` +
        '=============================');
    }
    else if (this.logLevel === Client.logLevel.full && config.method) {
      console.log('========== REQUEST ==========\n' +
        `${config.method.toUpperCase()} ${config.url}\n` +

        '--- Headers ---\n' +
        JSON.stringify(config.headers, null, 4) + '\n' +

        (
          config.params ?
            '--- URL Parameters ---\n' +
            JSON.stringify(config.params, null, 4) + '\n'
            : ''
        ) +

        (
          config.data ?
            '--- Body Data ---\n' +
              (typeof requestData === typeof String)
              ? requestData + '\n'
              : JSON.stringify(requestData, null, 4) + '\n'
            : ''
        ) +

        '--- Response ---\n' +
        JSON.stringify(axResponse.data, null, 4) + '\n' +

        '----------\n' +
        `isError: ${this.isResponseError(
          axResponse.data,
          axResponse.status,
          axResponse.statusText,
          config,
        ) !== undefined ? 'true' : 'false'}\n` +
        `code: ${axResponse.status}\n` +
        '=============================');
    }
  }
}



let instance: Client;
export function api(baseURL: string | undefined = undefined, apiKey: string | undefined = undefined): Client {
  if (!instance && baseURL && apiKey) {
    const source = axios.CancelToken.source();

    instance = new Client(baseURL, apiKey, source.token);
  }

  return instance;
}
