import { APP_TITLE } from '@global/constants';
import { NavigationState } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { FindInputStep, FindResultsStep } from '@screens';
import * as React from 'react';
import { AppRoutes } from './AppRoutes';



const Stack = createStackNavigator<FindPersonNavigatorParamsList>();

interface IFindPersonNavigatorProps {
  onIndexChange?: (index: number) => void,
}

interface IStateEventData {
  state: NavigationState
}

export const FindPersonNavigator: React.FC<IFindPersonNavigatorProps> = ({ onIndexChange }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}
      screenListeners={{
        state: (e) => onIndexChange?.((e.data as IStateEventData).state.index),
      }}
    >
      <Stack.Screen
        name={AppRoutes.FindInputStep}
        component={FindInputStep}
      />
      <Stack.Screen
        name={AppRoutes.FindResultsStep}
        component={FindResultsStep}
      />
    </Stack.Navigator>
  );
};


export type FindPersonNavigatorParamsList = {
  [AppRoutes.FindInputStep]: undefined,
  [AppRoutes.FindResultsStep]: undefined,
}
