import countries, { ICountryInfo } from './countries-emoji';
Object.keys(countries).forEach((key) => {
  countries[key].code = key;
});



export interface IPhoneNumber {
  code?: string
  number: string
}



export function getCountriesByCode(withCode = true): Record<string, ICountryInfo> {
  if (!withCode) return countries;

  return Object.keys(countries).reduce(
    (prev, current) =>
      countries[current].callingCode?.length ? { ...prev, [current]: countries[current] } : prev,
    {}
  );
}

export function getCountriesList(withCode = true) {
  return Object.values(getCountriesByCode(withCode));
}

export function splitPhoneNumber(phoneNumber: string): IPhoneNumber {
  if (phoneNumber[0] === '+') phoneNumber = phoneNumber.substring(1);

  const country = getCountriesList().find((c) => phoneNumber.indexOf(c.callingCode[0]) === 0);
  if (country) {
    const code = country.callingCode[0];
    return {
      code,
      number: phoneNumber.substring(code.length),
    };
  }
  else {
    return {
      number: phoneNumber,
    };
  }
}

export function joinPhoneNumber(splitted: IPhoneNumber, withPlus: boolean): string {
  const parts = [splitted.code, splitted.number].filter((p) => p);
  let phone = parts.join('');
  if (phone.length && phone[0] !== '+' && withPlus) phone = '+' + phone;
  return phone;
}

export function getCountryByPhoneCode(code: string) {
  return getCountriesList().find((c) => c.callingCode[0] === code);
}
