import { CloseIcon } from '@assets';
import { IconButton, IIconButtonProps } from 'native-base';
import React from 'react';



export const CloseButton: React.FC<IIconButtonProps> = (props) => {
  return (
    <IconButton variant="unstyled" icon={<CloseIcon />} {...props} />
  );
};
