import { mockPerson } from '@global/mock';
import { api, IPerson, Request } from '@api';
import { createModel } from '@rematch/core';
import { RootModel } from '.';



type AuthState = {
  user?: IPerson
  phone?: string
}



export const auth = createModel<RootModel>()({
  state: {} as AuthState,
  reducers: {
    setPhone(state: AuthState, phone: string) {
      return {
        ...state,
        phone,
      };
    },
    setUser(state: AuthState, user: IPerson) {
      return {
        ...state,
        user,
      };
    },
  },
  effects: dispatch => ({
    async loadAppData() {
      api().restoreSession();
    },
    async authCode(phone: string) {
      dispatch.auth.setPhone(phone);

      await Request.authCode(phone);
    },
    async verifyAuthCode(code: string, state) {
      const result = await Request.verifyAuthCode(state.auth.phone ?? '', code);

      api().setSession(result.data.token, result.data.expiration);
      api().storeSession();

      dispatch.auth.setUser(mockPerson());
    },
    async logOut() {
      api().setSession(undefined, undefined);
      api().storeSession();

      dispatch({ type: 'RESET' });
    },
  }),
});
