import { isEmpty } from 'lodash';
import { buildDetailsPage } from './helpers';
import { IComment } from './types';



export interface ICommentsParameters {
  data: IComment[]
}



export function buildCommentsPage({
  data,
}: ICommentsParameters) {
  return buildDetailsPage(
    `<h2>=== Comments ===</h2>
    ${!isEmpty(data) ? data!.map((r) => buildComment(r)).join('') : ''}`
  );
}



function buildComment(data: IComment): string {
  return `<table>
  <tr>
    <th>${data.author} commented in <a href="${data.source_link}" target="_blank">${data.source}</a></th>
  </tr>
  <tr>
    <td>${data.text}</td>
  </tr>
  <tr>
    <td>
      <a href="${data.comment_link}" target="_blank">Comment link</a> |
      <a href="${data.post_link}" target="_blank">Post link</a>
    </td>
  </tr>
  </table>
  <hr/>`;
}
