import { Pressable, Text, useTheme, VStack } from 'native-base';
import React from 'react';
import { useCallback } from 'react';



interface IProps {
  route: string,
  icon: (color: string) => JSX.Element,
  title: string,
  isActive: boolean,
  onPress: (route: string) => void,
}

export const TabBarButton: React.FC<IProps> = ({ route, icon, title, isActive, onPress }) => {
  const { colors } = useTheme();
  const inactiveColor = colors.grey;
  const activeColor = colors.dark;
  const color = isActive ? activeColor : inactiveColor;

  const handlePress = useCallback(() => onPress(route), [onPress, route]);

  return <Pressable w="16" onPress={handlePress} >
    <VStack space="1" alignItems="center">
      {icon(color)}
      <Text
        fontFamily="tabs"
        fontWeight="400"
        fontSize={14}
        color={color}
      >
        {title}
      </Text>
    </VStack>
  </Pressable>;
};
