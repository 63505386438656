import React, { useCallback, useEffect } from 'react';
import { ScreenContainer } from '@components';
import { AppRoutes } from '@navigation/AppRoutes';
import { RootNavigatorParamList } from '@navigation/RootNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';
import { useToast } from 'native-base';
import { Error } from '@utils/error';
import { api } from '@api';



type NavProps = StackScreenProps<RootNavigatorParamList, AppRoutes.Loading>;

export const LoadingScreen: React.FC<NavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();
  const toast = useToast();

  const navigateToNextScreen = useCallback(() => {
    const isAuthorized = api().isAuthorized();
    if (isAuthorized) navigation.replace(AppRoutes.MainTabsNavigator);
    else {
      api().setSession(undefined, undefined);
      api().storeSession();

      navigation.replace(AppRoutes.AuthNavigator);
    }
  }, [navigation]);

  const loadingOperations = useCallback(async () => {
    await dispatch.auth.loadAppData();
    // TODO: Add some loading operations here...
  }, [dispatch]);

  useEffect(() => {
    loadingOperations()
      .then(() => {
        setTimeout(navigateToNextScreen, 1000);
      })
      .catch((error: Error) => {
        toast.show({
          title: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenContainer
      loading
    />
  );
};
