/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgSingleColorProps, SvgSizeProps } from './props';



export const CheckmarkOn: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 20,
  height = 20,
  color = '#2AB91E',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <Path
      d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
      fill={color} />
    <Path
      d="M10 2.73438C5.98828 2.73438 2.73438 5.98828 2.73438 10C2.73438 14.0117 5.98828 17.2656 10 17.2656C14.0117 17.2656 17.2656 14.0117 17.2656 10C17.2656 5.98828 14.0117 2.73438 10 2.73438ZM13.7773 7.14258L9.66406 12.8457C9.60657 12.9259 9.53078 12.9913 9.44298 13.0364C9.35518 13.0815 9.25789 13.1051 9.15918 13.1051C9.06047 13.1051 8.96318 13.0815 8.87538 13.0364C8.78758 12.9913 8.71179 12.9259 8.6543 12.8457L6.2207 9.4707C6.14648 9.36719 6.2207 9.22266 6.34766 9.22266H7.26367C7.46484 9.22266 7.65234 9.32031 7.76953 9.48242L9.16016 11.4121L12.2305 7.1543C12.3477 6.99023 12.5371 6.89453 12.7363 6.89453H13.6523C13.7793 6.89453 13.8535 7.03906 13.7773 7.14258V7.14258Z"
      fill="white" />
    <Path
      d="M13.6523 6.89453H12.7363C12.5371 6.89453 12.3477 6.99023 12.2305 7.1543L9.16016 11.4121L7.76953 9.48242C7.65235 9.32031 7.46485 9.22266 7.26367 9.22266H6.34766C6.22071 9.22266 6.14649 9.36719 6.22071 9.4707L8.6543 12.8457C8.71179 12.9259 8.78758 12.9913 8.87538 13.0364C8.96318 13.0815 9.06047 13.1051 9.15918 13.1051C9.25789 13.1051 9.35518 13.0815 9.44299 13.0364C9.53079 12.9913 9.60658 12.9259 9.66407 12.8457L13.7773 7.14258C13.8535 7.03906 13.7793 6.89453 13.6523 6.89453Z"
      fill={color} />
  </Svg>;
};

export const CheckmarkOff: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 20,
  height = 20,
  color = '#F33737',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 19 19" fill="none">
    <Path
      d="M11.8333 6.83337L6.83331 11.8334M11.8333 11.8334L6.83331 6.83337L11.8333 11.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round" />
    <Path
      d="M9.33333 17.6667C13.9357 17.6667 17.6667 13.9357 17.6667 9.33333C17.6667 4.73096 13.9357 1 9.33333 1C4.73096 1 1 4.73096 1 9.33333C1 13.9357 4.73096 17.6667 9.33333 17.6667Z"
      stroke={color}
      strokeWidth="1.5" />
  </Svg>;
};
