import { IFindPersonInfo, IVerificationFilters, IVerifyPersonInfo } from '@global/types';
import { joinPhoneNumber } from '@utils/phone';
import { api } from './client';
import { IAuthResponse, IFindPersonResponse, IVerifyPersonResponse } from './models/responses';



export class Request {

  // ***** Authorization *****

  static authCode(phone: string) {
    return api().req<void>({
      method: 'POST',
      url: '/auth/code',
      noSession: true,
      data: {
        phone,
      },
    });
  }



  static verifyAuthCode(phone: string, code: string) {
    return api().req<IAuthResponse>({
      method: 'POST',
      url: '/auth/code/verify',
      noSession: true,
      data: {
        phone,
        code,
      },
    });
  }



  static authTokenRefresh(token: string, expiration: string) {
    return api().req<IAuthResponse>({
      method: 'POST',
      url: '/auth/token/refresh',
      noSession: true,
      data: {
        token,
        expiration,
      },
    });
  }



  // ***** Verification *****

  static ping() {
    return api().req<IAuthResponse>({
      method: 'GET',
      url: '/verification',
    });
  }



  static findPerson(info: IFindPersonInfo) {
    return api().req<IFindPersonResponse>({
      method: 'GET',
      url: '/verification/volunteer/find',
      params: {
        email: info.email,
      },
    });
  }

  static verifyPerson(info: IVerifyPersonInfo) {
    return api().req<void>({
      method: 'POST',
      url: '/verification/vreq/new',
      dontCheckMessageError: true,
      data: {
        'First Name': info.firstName,
        'Last Name': info.lastName,
        Social: info.socialID,
        Phone: info.phoneNumber && joinPhoneNumber(info.phoneNumber, false),
      },
    });
  }

  static getVerifications(filters: IVerificationFilters | undefined = undefined) {
    return api().req<IVerifyPersonResponse>({
      method: 'GET',
      url: '/verification/vreq/find/all',
      params: filters,
    });
  }

  static getVerificationDetails(id: string) {
    return api().req<IVerifyPersonResponse>({
      method: 'GET',
      url: '/verification/vreq/find/one',
      params: {
        id,
      },
    });
  }

}
