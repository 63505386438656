import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ScreenContainer,
  ScreenHeader,
  HTPadding,
  SearchInput,
  BubblesSelector,
  DefaultSidePadding,
} from '@components';
import { PERSON_STATUS_FILTERS } from '@global/constants';
import { HStack, Text, useTheme } from 'native-base';
import { VerificarionsList } from './components/VerificarionsList';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '@store';
import { VerificationFooter } from './components/VerificationsFooter';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeTabNavigatorParamList } from '@navigation/HomeTabNavigator';
import { AppRoutes } from '@navigation/AppRoutes';
import { RootNavigatorParamList } from '@navigation/RootNavigator';
import { handleAsyncOperation } from '@utils/promise';
import { isEmpty } from 'lodash';
import { IPerson, IVerification, VerificationStatus } from '@api';
import useDebounce from '@hooks/useDebounce';
import { convertToCamelCase } from '@utils/helpers';
import { getPersonFullName } from '@utils/personUtils';
import { Alert } from '@utils/Alert';



const AUTO_RELOAD_INTERVAL = 60000;



type INavProps =
  StackScreenProps<HomeTabNavigatorParamList, AppRoutes.VerificationsList> &
  StackScreenProps<RootNavigatorParamList>;

export const VerificationsListScreen: React.FC<INavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [statusFilters, setStatusFilters] = useState([PERSON_STATUS_FILTERS[0]]);

  const debouncedSearchText = useDebounce<string>(searchText, 500);

  const statusFiltersKeys = useMemo(() => statusFilters.map(f => f.key), [statusFilters]);

  const theme = useTheme();
  const bubblesInset = theme.sizes[DefaultSidePadding];

  const verifications = useSelector((state: RootState) => state.verification.verifications);

  const onSearchChange = useCallback((e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setSearchText(e.nativeEvent.text);
  }, []);

  const onSelectVerification = useCallback((verification: IVerification) => {
    const status = verification.fields['Verification status'];
    if (status !== VerificationStatus.Finished) {
      if (status === VerificationStatus.New || VerificationStatus.Assigned) {
        Alert.alert('Not available', 'Verification is not finished yet, please try again later');
      }
      else {
        Alert.alert('Not available', 'Verification failed, please try starting another verification');
      }
      return;
    }

    const person = convertToCamelCase(verification.fields) as IPerson;

    navigation.navigate(AppRoutes.VerificationDetails, {
      verificationId: verification.id,
      title: getPersonFullName(person),
      mode: 'main',
    });
  }, [navigation]);

  const onNewVerification = useCallback(() => {
    navigation.navigate(AppRoutes.VerifyPersonModal);
  }, [navigation]);

  const loadData = useCallback((loadingSetter: ((isLoading: boolean) => void) | undefined = undefined) => {
    const statusFilter = isEmpty(statusFiltersKeys[0]) ? undefined : statusFiltersKeys[0] as VerificationStatus;
    const nameFilter = isEmpty(debouncedSearchText) ? undefined : debouncedSearchText;

    handleAsyncOperation(
      dispatch.verification.loadVerifications({
        status: statusFilter,
        surname: nameFilter,
      }),
      loadingSetter,
    );
  }, [dispatch.verification, debouncedSearchText, statusFiltersKeys]);

  const reloadData = useCallback(() => {
    loadData(setIsReloading);
  }, [loadData]);

  useEffect(() => {
    loadData(setIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFiltersKeys, debouncedSearchText]);

  // Auto-updating
  useEffect(() => {
    const refreshToken = setInterval(() => {
      loadData();
    }, AUTO_RELOAD_INTERVAL);

    return () => {
      clearInterval(refreshToken);
    };
  }, [loadData]);

  return (
    <ScreenContainer
      paddingSides={HTPadding}
      header={
        <ScreenHeader
          title="Your Verifications"
          leftStyle="custom"
        />
      }
    >

      <SearchInput
        placeholder="Search by name"
        onChange={onSearchChange}
      />

      <BubblesSelector
        allowEmptySelection={false}
        singleSelection
        items={PERSON_STATUS_FILTERS}
        selectedItems={statusFiltersKeys}
        inset={bubblesInset}
        mx={-DefaultSidePadding}
        mt="6"
        h="7"
        onSelectionChange={setStatusFilters}
      />

      {searchText &&
        <HStack mt="10">
          <Text
            fontSize="lg"
            fontWeight="700"
            children="Search results for: "
          />
          <Text
            flex="1"
            numberOfLines={1}
            fontSize="lg"
            fontWeight="700"
            underline
            children={searchText}
          />
        </HStack>
      }

      <VerificarionsList
        mt="10"
        data={verifications}
        footer={<VerificationFooter onNewVerification={onNewVerification} />}
        onSelectItem={onSelectVerification}
        onRefresh={reloadData}
        refreshing={isReloading}
        isLoading={isLoading}
      />

    </ScreenContainer>
  );
};
