import { Models } from '@rematch/core';
import { auth } from './auth';
import { verification } from './verification';



export interface RootModel extends Models<RootModel> {
    auth: typeof auth;
    verification: typeof verification;
}

export const models: RootModel = {
  auth,
  verification,
};
