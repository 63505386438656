import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Center,
  HStack,
  Pressable,
  Text,
  useToast,
  VStack,
} from 'native-base';
import { HTPadding, ScreenContainer, ScreenHeader, TextInput } from '@components';
import { CopyIcon, TestQRCode } from '@assets';
import faker from '@faker-js/faker';
import * as Clipboard from 'expo-clipboard';



export const AuthSetupScreen: React.FC = () => {
  const token = useMemo(() => faker.random.alphaNumeric(128), []);
  const toast = useToast();

  const copyToken = useCallback(() => {
    Clipboard.setString(token);
    toast.show({
      title: 'Token copied to clipboard',
    });
  }, [toast, token]);

  return (
    <ScreenContainer
      paddingSides={HTPadding}
      header={
        <ScreenHeader
          title="Setup 2-factor auth"
          leftStyle="close"
          transparent
        />
      }
    >
      <VStack space="4" alignItems="stretch">
        <HStack>
          <Text
            fontSize="md"
            fontWeight="700"
            children="1. "
          />
          <Text fontSize="md" flex="1">
            Install some application for 2-factor authentication like Authy,
            Google Authenticator or something else.
          </Text>
        </HStack>

        <HStack>
          <Text
            fontSize="md"
            fontWeight="700"
            children="2. "
          />
          <Text fontSize="md" flex="1">
            Scan this QR-code or copy token to add account to 2-factor authentication application.
          </Text>
        </HStack>

        <Center>
          <TestQRCode />
        </Center>

        <Pressable onPress={copyToken}>
          <TextInput
            pointerEvents="none"
            isReadOnly
            rightElement={<Box mr="2"><CopyIcon /></Box>}
            value={token}
          />
        </Pressable>

        <HStack>
          <Text
            fontSize="md"
            fontWeight="700"
            children="3. "
          />
          <Text fontSize="md" flex="1">
            Use temporary code from 2-factor authentication application for logging in.
          </Text>
        </HStack>
      </VStack>
    </ScreenContainer>
  );
};
