import { APP_TITLE } from '@global/constants';
import { createStackNavigator } from '@react-navigation/stack';
import { SettingsScreen } from '@screens';
import * as React from 'react';
import { AppRoutes } from './AppRoutes';



const Stack = createStackNavigator<SettingsTabNavigatorParamList>();

export const SettingsTabNavigator: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}>
      <Stack.Screen
        name={AppRoutes.Settings}
        component={SettingsScreen}
      />
    </Stack.Navigator>
  );
};


export type SettingsTabNavigatorParamList = {
  [AppRoutes.Settings]: undefined,
}
