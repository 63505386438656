import { PlusButton } from '@assets';
import { Button, IStackProps, Text, VStack } from 'native-base';
import React from 'react';



interface IProps extends IStackProps {
  onNewVerification?: () => void
}

export const VerificationFooter: React.FC<IProps> = ({
  onNewVerification,
  ...props
}) => {
  return (
    <VStack
      my="16"
      space="2"
      alignItems="center"
      {...props}
    >
      <Text
        fontSize="md"
        fontWeight="700"
        children="Is there a new person you need to verify?"
      />

      <Text
        fontSize="md"
        children="Get started with the verification process"
      />

      <Button
        h="12"
        mt="2"
        size="lg"
        px="3"
        onPress={onNewVerification}
        leftIcon={
          <PlusButton />
        }
      >
        New Verification
      </Button>
    </VStack>
  );
};
