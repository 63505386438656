import { IInputProps, Input } from 'native-base';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';



export interface ITextInputProps extends IInputProps {
  invalid?: boolean
}

export const TextInput = forwardRef<typeof Input, ITextInputProps>(({
  value,
  onChange,
  invalid,
  ...props
}, ref) => {
  const [isEmpty, setIsEmpty] = useState(!value);

  useEffect(() => {
    setIsEmpty(!value);
  }, [value]);

  const handleChange = useCallback((e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setIsEmpty(!e.nativeEvent.text);
    onChange?.(e);
  }, [onChange]);

  return (
    <Input
      ref={ref}
      value={value}
      onChange={handleChange}
      borderColor={invalid ? 'red.400' : undefined}
      variant={isEmpty ? 'empty' : 'unstyled'}
      {...props}
    />
  );
});
