import loadSvgFile from '@utils/svg';

export const TabSettings = require('./png/tab-settings.png');
export const RadioButtonOn = require('./png/radio-on.png');
export const RadioButtonOff = require('./png/radio-off.png');

export * from './svg/tabbar';
export * from './svg/navbar';
export * from './svg/checkmarks';
export * from './svg/timeline';
export * from './svg/other';

export const TestQRCode = loadSvgFile(require('./svg/qrcode.svg'), 200, 200);
