import { IPerson } from './../api/models/entities';



export function getPersonFullName(person: IPerson) {
  return [person.firstName, person.lastName].filter(p => p).join(' ');
}



export function getPersonShortName(person: IPerson) {
  if (person.lastName) {
    const lastNameShort = person.lastName.at(0) + '.';
    return [person.firstName, lastNameShort].filter(p => p).join(' ');
  }
  return person.firstName ?? '';
}
