/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgSingleColorProps, SvgSizeProps } from './props';



export const HomeIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 24,
  height = 24,
  color = '#6D6D6D',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <Path
      d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9 22V12H15V22"
      fill="white"
    />
    <Path
      d="M9 22V12H15V22"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>;
};



export const VerifyIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 24,
  height = 24,
  color = '#6D6D6D',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <Path
      d="M0 4.33333C0 3.18406 0.456546 2.08186 1.2692 1.2692C2.08186 0.456546 3.18406 0 4.33333 0H19.6667C20.8159 0 21.9181 0.456546 22.7308 1.2692C23.5435 2.08186 24 3.18406 24 4.33333V19.6667C24 20.8159 23.5435 21.9181 22.7308 22.7308C21.9181 23.5435 20.8159 24 19.6667 24H4.33333C3.18406 24 2.08186 23.5435 1.2692 22.7308C0.456546 21.9181 0 20.8159 0 19.6667V4.33333ZM13 6.33333C13 6.06812 12.8946 5.81376 12.7071 5.62623C12.5196 5.43869 12.2652 5.33333 12 5.33333C11.7348 5.33333 11.4804 5.43869 11.2929 5.62623C11.1054 5.81376 11 6.06812 11 6.33333V11H6.33333C6.06812 11 5.81376 11.1054 5.62623 11.2929C5.43869 11.4804 5.33333 11.7348 5.33333 12C5.33333 12.2652 5.43869 12.5196 5.62623 12.7071C5.81376 12.8946 6.06812 13 6.33333 13H11V17.6667C11 17.9319 11.1054 18.1862 11.2929 18.3738C11.4804 18.5613 11.7348 18.6667 12 18.6667C12.2652 18.6667 12.5196 18.5613 12.7071 18.3738C12.8946 18.1862 13 17.9319 13 17.6667V13H17.6667C17.9319 13 18.1862 12.8946 18.3738 12.7071C18.5613 12.5196 18.6667 12.2652 18.6667 12C18.6667 11.7348 18.5613 11.4804 18.3738 11.2929C18.1862 11.1054 17.9319 11 17.6667 11H13V6.33333Z"
      fill={color}
    />
  </Svg>;
};
