import React from 'react';
import { Box, IBoxProps, Pressable, Text } from 'native-base';



interface IProps extends IBoxProps {
  title: string
  isSelected: boolean
  onPress: () => void
}

export const BubbleItem: React.FC<IProps> = ({
  title,
  isSelected,
  onPress,
  ...props
}) => {
  return (
    <Pressable onPress={onPress}>
      <Box
        flex="1"
        rounded="full"
        px="2"
        bg={isSelected ? 'primary.100' : 'transparent'}
        borderWidth="1"
        borderColor={isSelected ? 'primary.100' : 'lightGrey2'}
        justifyContent="center"
        {...props}
      >
        <Text
          fontSize="sm"
          color={isSelected ? 'white' : 'lightGrey2'}
        >
          {title}
        </Text>
      </Box>
    </Pressable>
  );
};
