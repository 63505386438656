import React, { useCallback, useState } from 'react';
import {
  Heading, ScrollView, Text, VStack,
} from 'native-base';
import { HPadding, RadioButton, ScreenContainer, VerifyFooter } from '@components';
import { CheckOption } from '../components/CheckOption';
import { AppRoutes } from '@navigation/AppRoutes';
import { HomeTabNavigatorParamList } from '@navigation/HomeTabNavigator';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { TimeOption } from '@global/types';
import { VERIFICATION_CHECKS, TIME_OPTIONS } from '@global/constants';
import { handleAsyncOperation } from '@utils/promise';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';



type INavProps = BottomTabScreenProps<HomeTabNavigatorParamList>;

export const VerifyFinalizeStep: React.FC<INavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();

  const [timeOption, setTimeOption] = useState<TimeOption>('15m');

  const [isLoading, setIsLoading] = useState(false);

  const onContinue = useCallback(() => {
    handleAsyncOperation(
      dispatch.verification.verifyPerson(undefined),
      setIsLoading,
      () => navigation.navigate(AppRoutes.VerificationsList),
    );
  }, [dispatch.verification, navigation]);

  return (
    <ScreenContainer
      paddingSides={HPadding}
      loading={isLoading}>
      <Heading size="lg" pt="2" pb="4">Step 3: Finalize Request</Heading>

      <ScrollView flex="1" mb="4" showsVerticalScrollIndicator={false}>
        <Heading size="sm" mb="3">
          How soon do you need the background check?
        </Heading>
        <Text fontSize="md" mb="4">
          The sooner you need the results, the less rigorous the background check will be.
        </Text>

        <VStack mb="6">
          {TIME_OPTIONS.map((item) =>
            <RadioButton
              key={item.key}
              value={item.key}
              currentValue={timeOption}
              setCurrentValue={setTimeOption as ((value: string) => void)}
              title={item.title}
            />
          )}
        </VStack>

        <Heading size="sm" mb="3">
          What checks will my verification request make?
        </Heading>

        <VStack>
          {VERIFICATION_CHECKS.map((item, index) =>
            <CheckOption
              key={`item_${index}`}
              title={item.title}
              isOn={item.timeOptions.includes(timeOption)}
            />
          )}
        </VStack>

      </ScrollView>

      <VerifyFooter
        forwardTitle="Start Verification Process →"
        backTitle="← Or make changes to contact info"
        onForwardPress={onContinue}
      />
    </ScreenContainer >
  );
};
