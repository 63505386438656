import { Heading, VStack, IStackProps, Text } from 'native-base';
import React from 'react';



interface IProps extends IStackProps {
  title: string,
  value?: string,
}

export const ValueReview: React.FC<IProps> = ({
  title,
  value,
  ...props
}) => {
  return (
    <VStack {...props} space="1">
      <Heading size="sm" letterSpacing={2}>{title}</Heading>
      <Text fontSize="md" color="darkGrey">{value}</Text>
    </VStack>
  );
};
