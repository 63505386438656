import _ from 'lodash';
import { Linking } from 'react-native';



export const delay = (ms = 1000): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const scheduleAtTime = (
  time: Date,
  callback: () => void,
): NodeJS.Timeout => {
  const now = new Date();
  const delta = time.getTime() - now.getTime();

  return setTimeout(callback, delta);
};

export const openUrl = (url: string): void => {
  Linking.canOpenURL(url).then((canOpen) => {
    if (canOpen) {
      Linking.openURL(url);
    }
  })
    .catch((e) => {
      console.log(e);
    });
};

export const mailTo = (email: string | undefined): void => {
  if (email === undefined) {
    return;
  }

  openUrl(`mailto:${email}`);
};

export const callTo = (phone: string | undefined): void => {
  if (phone === undefined) {
    return;
  }

  openUrl(`tel:${phone}`);
};



export const hhmmss = (duration: number): {
  hours: number,
  minutes: number,
  seconds: number,
} => {
  if (duration === 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = duration - (hours * 3600) - (minutes * 60);

  return {
    hours,
    minutes,
    seconds,
  };
};


export const hhmmssString = (duration: number): string => {
  const { hours, minutes, seconds } = hhmmss(duration);
  let minutesDisplay = `${minutes}`;
  if (minutes < 10) {
    minutesDisplay = `0${minutes}`;
  }

  let secondsDisplay = `${seconds}`;
  if (seconds < 10) {
    secondsDisplay = `0${seconds}`;
  }

  return `${hours}:${minutesDisplay}:${secondsDisplay}`;
};



export const convertToCamelCase = (obj: Record<string, unknown>): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  _.forOwn(obj, function (v, k) {
    if (_.isPlainObject(v)) {
      v = convertToCamelCase(v as Record<string, unknown>);
    }
    result[_.camelCase(k)] = v;
  });

  return result;
};
