import { HomeIcon, TabSettings, VerifyIcon } from '@assets';
import { AppRoutes } from '@navigation/AppRoutes';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { Actionsheet, Box, HStack, Image } from 'native-base';
import React, { useCallback, useMemo, useState } from 'react';
import { TabBarButton } from './TabBarButton';



export const TabBar: React.FC<BottomTabBarProps> = ({ navigation, state, insets }) => {
  const [actionIsOpen, setActionIsOpen] = useState(false);

  const closeActionSheet = useCallback(() => {
    setActionIsOpen(false);
  }, []);

  const onVerifyPerson = useCallback(() => {
    setActionIsOpen(false);
    navigation.navigate(AppRoutes.VerifyPersonModal);
  }, [navigation]);

  const onFindPerson = useCallback(() => {
    setActionIsOpen(false);
    navigation.navigate(AppRoutes.FindPersonModal);
  }, [navigation]);

  const tabs = useMemo(() => [
    {
      route: AppRoutes.HomeTabNavigator,
      icon: (color: string) => <HomeIcon color={color} />,
      title: 'Home',
      onPress: (route: string) => navigation.navigate(route),
    },
    {
      route: '',
      icon: () => <VerifyIcon />,
      title: 'Verify',
      onPress: () => setActionIsOpen(true),
    },
    {
      route: AppRoutes.SettingsTabNavigator,
      icon: (_) => <Image source={TabSettings} w="6" h="6" alt="Settings tab" />,
      title: 'Settings',
      onPress: (route: string) => navigation.navigate(route),
    },
  ], [navigation]);

  return <Box pb={`${insets.bottom}px`} bg="bgClear" shadow="9">
    <HStack h="70px" alignItems="center" justifyContent="space-evenly">
      {tabs.map((tab) => <TabBarButton
        key={tab.title}
        route={tab.route}
        icon={tab.icon}
        title={tab.title}
        isActive={state.routeNames[state.index] === tab.route}
        onPress={tab.onPress}
      />)}
    </HStack>

    <Actionsheet isOpen={actionIsOpen} onClose={closeActionSheet}>
      <Actionsheet.Content>
        <Actionsheet.Item onPress={onVerifyPerson}>Verify person</Actionsheet.Item>
        <Actionsheet.Item onPress={onFindPerson}>Find volunteer</Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  </Box>;
};
