import { Box } from 'native-base';
import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';



interface IProps {
  ratio?: number
}

export const WebRootLayout: React.FC<IProps> = ({
  ratio = 0.8,
  children,
}) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const maxWidth = windowHeight * ratio;

  if (Platform.OS !== 'web') {
    return <>{children}</>;
  }

  return (
    <Box
      flex="1"
      w={Math.min(maxWidth, windowWidth)}
      ml={Math.max((windowWidth - maxWidth) / 2, 0)}
    >
      {children}
    </Box>
  );
};
