import React, { useCallback, useMemo, useState } from 'react';
import { Box, FlatList, IBoxProps } from 'native-base';
import { BubbleItem } from './BubbleItem';



export interface IBubbleItem {
  key: string,
  title: string,
}

interface IBubblesSelectorProps extends IBoxProps {
  items: IBubbleItem[]
  selectedItems?: string[]
  singleSelection?: boolean
  allowEmptySelection?: boolean
  inset?: number
  onSelectionChange?: (selectedItems: IBubbleItem[]) => void
}

export const BubblesSelector: React.FC<IBubblesSelectorProps> = ({
  items,
  selectedItems = [],
  singleSelection = false,
  allowEmptySelection = true,
  inset = 0,
  onSelectionChange,
  ...props
}) => {
  const [selected, setSelected] = useState(selectedItems);

  const toggleItem = useCallback((key: string) => {
    const isSelected = selected.includes(key);
    let newSelected: string[] | undefined;

    if (isSelected) {
      const deselected = selected.filter(i => i !== key);
      if (deselected.length || allowEmptySelection) {
        newSelected = deselected;
      }
    }
    else {
      if (singleSelection) newSelected = [key];
      else newSelected = [...selected, key];
    }

    if (newSelected) {
      setSelected(newSelected);

      const bubbles = items.filter(b => newSelected?.includes(b.key));
      onSelectionChange?.(bubbles);
    }
  }, [allowEmptySelection, items, onSelectionChange, selected, singleSelection]);

  const renderItem = useCallback(({ item, index }: { item: IBubbleItem, index: number }) => {
    const isLast = index === items.length - 1;
    return (
      <BubbleItem
        title={item.title}
        isSelected={selected.includes(item.key)}
        onPress={() => toggleItem(item.key)}
        mr={isLast ? '0' : '2'}
      />
    );
  }, [items.length, selected, toggleItem]);

  const keyExtractor = useCallback((item) => item.key, []);

  const containerStyle = useMemo(() => ({ paddingHorizontal: inset }), [inset]);

  return (
    <Box {...props}>
      <FlatList
        horizontal
        keyExtractor={keyExtractor}
        contentContainerStyle={containerStyle}
        data={items}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        extraData={selectedItems}
      />
    </Box>
  );
};
