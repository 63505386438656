import React, { useCallback, useMemo, useState } from 'react';
import { Box, Divider, FlatList, HStack, IModalProps, Modal, Pressable, Text } from 'native-base';
import { getCountriesList } from '@utils/phone';
import { ICountryInfo } from '@utils/countries-emoji';
import emoji from 'node-emoji';
import { TextInput } from '../TextInput';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';



interface IProps extends IModalProps {
  onSelect: (country: ICountryInfo) => void
}

export const CountryCodeModal: React.FC<IProps> = ({
  onSelect,
  ...modalProps
}) => {
  const data = useMemo(() => getCountriesList(), []);
  const [searchText, setSearchText] = useState('');
  const filteredData = useMemo(() => {
    if (!searchText) return data;
    const term = searchText.toLowerCase();

    return data.filter(
      (c) =>
        c.name.common?.toLowerCase()?.indexOf(term) !== -1 ||
        c.callingCode[0]?.startsWith(term)
    );
  }, [data, searchText]);

  const onChangeSearchText = useCallback((e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setSearchText(e.nativeEvent.text);
  }, []);

  const renderItem = useCallback(
    ({ item }: { item: ICountryInfo }) => {
      const onPress = () => {
        onSelect?.(item);
      };

      const name = item.name.common;

      return (
        <Pressable onPress={onPress}>
          <HStack h="12" alignItems="center" space="4">
            <Text fontSize="3xl">{emoji.get(item.flag)}</Text>
            <Text fontSize="md" flex="1" numberOfLines={1}>{name}</Text>
            <Text>+{item.callingCode[0]}</Text>
          </HStack>
          <Divider />
        </Pressable>
      );
    }, [onSelect]
  );

  const keyExtractor = useCallback((item) => item.name.common, []);

  return (
    <Modal {...modalProps}>
      <Modal.Content maxH="3/4" flex="1">
        <Modal.CloseButton />
        <Modal.Header _text={{ fontWeight: '700' }}>
          Select country phone code
        </Modal.Header>
        <Box flex="1" pt="2" px="2">
          <TextInput
            placeholder="Search"
            mb="4"
            value={searchText}
            onChange={onChangeSearchText}
          />
          <FlatList
            flex="1"
            data={filteredData}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
          />
        </Box>
      </Modal.Content>
    </Modal>
  );
};
