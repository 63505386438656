import errorHandler from './errorHandler';



export function handleAsyncOperation<T>(
  promise: Promise<T>,
  loadingSetter: ((isLoading: boolean) => void) | undefined = undefined,
  then: ((result: T) => void) | undefined = undefined,
  options: {
    errorMessage?: string,
  } = {},
) {
  loadingSetter?.(true);

  promise
    .then(then)
    .catch(e => errorHandler().handleError(options?.errorMessage ?? e))
    .finally(() => loadingSetter?.(false));
}
