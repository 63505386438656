import React, { useCallback } from 'react';
import {
  Button,
  Center,
  Heading,
} from 'native-base';
import { HTPadding, ScreenContainer, ScreenHeader } from '@components';
import { RootNavigatorParamList } from '@navigation/RootNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { AppRoutes } from '@navigation/AppRoutes';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';



type INavProps = StackScreenProps<RootNavigatorParamList>;

export const SettingsScreen: React.FC<INavProps> = ({
  navigation,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const onLogOut = useCallback(() => {
    // TODO: Handle error
    dispatch.auth.logOut()
      .then(() => navigation.replace(AppRoutes.AuthNavigator))
      .catch((error) => console.log(error));
  }, [dispatch.auth, navigation]);

  return (
    <ScreenContainer
      paddingSides={HTPadding}
      header={
        <ScreenHeader
          title="Settings"
          leftStyle="custom"
        />
      }
    >
      <Center flex="1">
        <Heading size="sm">TODO</Heading>

        <Button mt="10" onPress={onLogOut}>
          Log Out
        </Button>

        <Button mt="2" onPress={() => dispatch.verification.ping()}>
          PING
        </Button>
      </Center>
    </ScreenContainer>
  );
};
