import React, { useEffect } from 'react';
import { NativeBaseProvider } from 'native-base';
import { RootProvider } from '@store';
import { NavigationContainer, StackActions } from '@react-navigation/native';
import { RootNavigator } from '@navigation/RootNavigator';
import { useFonts } from 'expo-font';
import { CLIENT_EVENT_SESSION_EXPIRED } from './src/api/client';
import { AppRoutes } from './src/navigation/AppRoutes';
import { store } from './src/store/RootProvider';
import { theme } from '@utils/theme';
import { WebRootLayout } from '@components';
import { api } from '@api';
import { createNavigationContainerRef } from '@react-navigation/native';
import { showMessage } from '@utils/alertHelper';
import Constants from 'expo-constants';

export const navigationRef = createNavigationContainerRef();



const App: React.FC = () => {
  const [fontsLoaded] = useFonts({
    'SourceSansPro-Regular': require('./assets/fonts/SourceSansPro-Regular.ttf'),
    'PTSans-Regular': require('./assets/fonts/PTSans-Regular.ttf'),
    'PTSans-Italic': require('./assets/fonts/PTSans-Italic.ttf'),
    'PTSans-Bold': require('./assets/fonts/PTSans-Bold.ttf'),
    'PTSans-BoldItalic': require('./assets/fonts/PTSans-BoldItalic.ttf'),
  });

  useEffect(() => {
    api(
      Constants.manifest?.extra?.backendApiUrl,
      Constants.manifest?.extra?.backendApiKey,
    );

    // Navigate to login, if session was reset
    const onResetSession = () => {
      store.dispatch({ type: 'RESET' });
      navigationRef.dispatch(StackActions.replace(AppRoutes.AuthNavigator));
      showMessage('Session', 'Session expired. Please log in again');
    };

    api().eventEmitter.on(CLIENT_EVENT_SESSION_EXPIRED, onResetSession);

    return () => {
      api().eventEmitter.removeListener(CLIENT_EVENT_SESSION_EXPIRED, onResetSession);
    };
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <RootProvider>
      <NativeBaseProvider theme={theme}>
        <WebRootLayout>
          <NavigationContainer ref={navigationRef}>
            <RootNavigator />
          </NavigationContainer>
        </WebRootLayout>
      </NativeBaseProvider>
    </RootProvider>
  );
};

export default App;
