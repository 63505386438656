import { APP_TITLE } from '@global/constants';
import { NavigationState } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { VerifyFinalizeStep, VerifyInputStep, VerifyReviewStep } from '@screens';
import * as React from 'react';
import { AppRoutes } from './AppRoutes';



const Stack = createStackNavigator<VerifyPersonNavigatorParamsList>();

interface IVerifyPersonNavigatorProps {
  onIndexChange?: (index: number) => void,
}

interface IStateEventData {
  state: NavigationState
}

export const VerifyPersonNavigator: React.FC<IVerifyPersonNavigatorProps> = ({ onIndexChange }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}
      screenListeners={{
        state: (e) => onIndexChange?.((e.data as IStateEventData).state.index),
      }}
    >
      <Stack.Screen
        name={AppRoutes.VerifyInputStep}
        component={VerifyInputStep}
      />
      <Stack.Screen
        name={AppRoutes.VerifyReviewStep}
        component={VerifyReviewStep}
      />
      <Stack.Screen
        name={AppRoutes.VerifyFinalizeStep}
        component={VerifyFinalizeStep}
      />
    </Stack.Navigator>
  );
};


export type VerifyPersonNavigatorParamsList = {
  [AppRoutes.VerifyInputStep]: undefined,
  [AppRoutes.VerifyReviewStep]: undefined,
  [AppRoutes.VerifyFinalizeStep]: undefined,
}
