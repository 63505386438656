export function isValidEmail(email?: string): boolean {
  if (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return false;
}



export function isValidPhone(phone?: string): boolean {
  if (phone) {
    const re = /^(\+)?[0-9]{11,13}$/;
    return re.test(String(phone));
  }

  return false;
}
