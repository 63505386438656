import { IPhoneNumber } from '@utils/phone';
import React from 'react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { IPhoneInputProps, PhoneInput } from '../PhoneInput';
import { ITextInputProps, TextInput } from '../TextInput';



interface IFormFieldProps<ValueType> {
  name: string
  rules?: UseControllerProps['rules']
  defaultValue?: ValueType
}

interface IControllerProps<ValueType> {
  value: ValueType
  onChange: (value: ValueType) => void,
  onBlur: () => void,
  invalid: boolean
  isTouched: boolean
  isDirty: boolean
}



export function wrapField<ValueType, ComponentProps>(
  Component: React.FC<ComponentProps>,
  mapControllerProps: (
    controllerProps: IControllerProps<ValueType>,
    componentProps: ComponentProps,
  ) => Partial<ComponentProps>,
): React.FC<
  IFormFieldProps<ValueType> &
  Omit<ComponentProps, keyof IFormFieldProps<ValueType>>
> {
  return ({ name, rules, defaultValue, ...componentProps }) => {
    const { control } = useFormContext();
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { invalid, isTouched, isDirty },
    } = useController({
      control: control,
      rules,
      name,
      defaultValue,
    });

    const mappedProps = mapControllerProps({
      value,
      onChange,
      onBlur,
      invalid,
      isTouched,
      isDirty,
    }, componentProps as unknown as ComponentProps);

    return (
      <Component
        ref={ref}
        {...mappedProps}
        {...componentProps as unknown as ComponentProps}
      />
    );
  };
}



export const FormTextInput = wrapField<string, ITextInputProps>(
  TextInput,
  ({ value, onChange, onBlur, invalid }) => {
    return {
      value,
      onChange: (e) => onChange(e.nativeEvent.text),
      onBlur,
      invalid,
    };
  }
);



export const FormPhoneInput = wrapField<IPhoneNumber, IPhoneInputProps>(
  PhoneInput,
  ({ value, onChange, onBlur, invalid }) => {
    return {
      value,
      onChange,
      onBlur,
      invalid,
    };
  }
);
