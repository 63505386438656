import React, { useCallback } from 'react';
import {
  Divider,
  FlatList,
  Heading,
  Text,
  VStack,
} from 'native-base';
import { IFindPersonResult } from '../../../api/models/entities';
import { HPadding, ScreenContainer, ValueReview } from '@components';
import { AppRoutes } from '@navigation/AppRoutes';
import { StackScreenProps } from '@react-navigation/stack';
import { VerifyFooter } from '@components';
import { FindPersonNavigatorParamsList } from '@navigation/FindPersonNavigator';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { StyleSheet } from 'react-native';



type INavProps = StackScreenProps<FindPersonNavigatorParamsList, AppRoutes.FindResultsStep>;

export const FindResultsStep: React.FC<INavProps> = ({ navigation }) => {
  const personInfo = useSelector<RootState, IFindPersonResult[] | undefined>(
    state => state.verification.findPersonResults
  );

  const onContinue = useCallback(() => {
    if (navigation.getParent()?.canGoBack()) {
      navigation.getParent()?.goBack();
    }
  }, [navigation]);

  const renderPerson = ({ item, index }: { item: IFindPersonResult, index: number }) => (
    <VStack space="6">
      <Heading
        children={`Person #${index + 1}`}
      />
      <Divider />

      {Object.keys(item?.fields ?? {}).map(key => {
        return <ValueReview
          key={key}
          title={key.toUpperCase()}
          value={(item?.fields[key] ?? '').toString()}
        />;
      })}
    </VStack>
  );

  return (
    <ScreenContainer paddingSides={HPadding}>
      <Heading size="lg" pt="2" pb="8">Step 2: Results</Heading>

      <FlatList
        flex="1"
        mb="4"
        contentContainerStyle={styles.listContentContainer}
        keyExtractor={(_, index) => `person_${index}`}
        data={personInfo}
        renderItem={renderPerson}
        ListEmptyComponent={
          <Text
            fontSize="md"
            textAlign="center"
            children="No records found"
          />
        }
      />

      <VerifyFooter
        forwardTitle="Close"
        backTitle="← Change email"
        onForwardPress={onContinue}
      />

    </ScreenContainer>
  );
};



const styles = StyleSheet.create({
  listContentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
});
