import { Platform } from 'react-native';
import { isEmpty, camelCase, startCase } from 'lodash';
import moment from 'moment';
import {
  buildTableItemsFromStringObect,
  formatPhoneNumbers,
  buildTitleValueTable,
  successValueTransformer,
  buildTableItemsFromObect,
  formatWorkTimeRange,
  formatLinks,
  formatPhoto,
  highlightLevel,
  buildDetailsPage,
} from './helpers';
import {
  ITableItemsList,
  IRootItem,
  IAccounts,
  ICandidate,
  ICreditActivity,
  IDemography,
  IResume,
  IMarketplace,
  ISubject,
  ISubjectStatusItem,
  IVerificationResultData,
  IRhetoricByFriends,
  ITableItem,
} from './types';
/* eslint-disable @typescript-eslint/no-unused-vars */



export interface IDetailsParameters {
  data: IVerificationResultData
  searchData: Record<string, string>
  submittedAt: Date
  status: string
}




export function buildMainPage({
  data,
  ...rest
}: IDetailsParameters): string {
  return buildDetailsPage(
    buildGeneralInfo(rest) +
    Object.values(data?.items ?? {}).map((i, index) => buildRootItem(i, index)).join('\n')
  );
}



function buildGeneralInfo({
  searchData,
  submittedAt,
  status,
}: {
  searchData: Record<string, string>,
  submittedAt: Date,
  status: string,
}): string {
  const searchDataInfo: ITableItemsList = [
    {
      title: 'Search data',
    },
    ...buildTableItemsFromStringObect(searchData, [formatPhoneNumbers]),
    {
      title: 'Info',
    },
    {
      title: 'Submitted at',
      value: moment(submittedAt).format('LLL'),
    },
    {
      title: 'Status',
      value: status,
    },
  ];

  return `
    ${buildTitleValueTable(searchDataInfo)}
    `;
}



function buildRootItem(data: IRootItem, index: number): string {
  const generalItemInfo: ITableItemsList = [
    {
      title: 'Success',
      value: data.success?.toString() ?? '',
      valueTransformers: [(v) => successValueTransformer(v, !isEmpty(data.phone))],
    },
    {
      title: 'Fuzzy matching',
      value: data.fuzzy_matching?.toString() ?? '',
    },
    {
      title: 'Key',
      value: data.key ?? '',
    },
    {
      title: 'Success',
      value: data.reason ?? '',
    },
  ];

  return `
    <h2>=== Result #${index + 1} ===</h2>
    ${buildTitleValueTable(generalItemInfo)}
  
    ${!isEmpty(data.demography) ? buildDemography(data.demography!) : ''}
    ${!isEmpty(data.resumes) ? data.resumes!.map((r, index) => buildResume(r, index)).join('') : ''}
    ${!isEmpty(data.subjects) ? data.subjects!.map((s, sIndex) => buildSubject(s, index, sIndex)).join('') : ''}
    ${!isEmpty(data.marketplaces) ? buildMarketplaces(data.marketplaces!) : ''}
    ${!isEmpty(data.candidates) ? buildCandidates(data.candidates!) : ''}
    ${!isEmpty(data.accounts) ? buildAccounts(data.accounts!) : ''}
    ${!isEmpty(data.credit_activity) ? buildCreditActivity(data.credit_activity!) : ''}
    `;
}



function buildAccounts(data: IAccounts): string {
  return `
    <h3>Accounts:</h3>
    <p class="noData">[No Data, need example]</p>
    <hr/>
    `;
}



function buildCandidates(data: ICandidate[]): string {
  const merged = data.reduce((prev, current) => ({ ...prev, ...current }), {});
  const tableData = buildTableItemsFromObect(
    merged,
    v => v.join(', '),
    undefined,
    k => startCase(camelCase(k)),
  );

  return `
    <h3>Candidates:</h3>
    ${buildTitleValueTable(tableData)}
    <hr/>
    `;
}



function buildCreditActivity(data: ICreditActivity): string {
  return `
    <h3>Credit Activity:</h3>
    <p class="noData">[No Data, need example]</p>
    <hr/>
    `;
}



function buildDemography(data: IDemography): string {
  const buildPossibleLocation = (location: Record<string, string>, index: number): ITableItemsList => {
    return [
      {
        title: `Possible location #${index + 1}`,
      },
      ...buildTableItemsFromStringObect(location, undefined, k => startCase(camelCase(k))),
    ];
  };

  const generalData: ITableItemsList = [
    {
      title: 'Name',
      value: data.name ?? '',
    },
    {
      title: 'Age',
      value: data.age?.toString() ?? '',
    },
    {
      title: 'Gender',
      value: data.gender ?? '',
    },
    {
      title: 'Country',
      value: data.country?.join(', ') ?? '',
    },
  ];

  const educationData = [
    {
      title: 'Education',
    },
    ...(data.educations?.map(e => ({
      title: e.type ?? '',
      value: e.name ?? '',
    })) ?? []),
  ];

  const possibleLocations = [
    ...(
      (data.possible_location ?? [])
        .map((l, index) => buildPossibleLocation(l, index))
        .reduce((prev, current) => [...prev, ...current], [])
    ),
  ];

  const work = [
    {
      title: 'Work',
    },
    ...(data.work?.map(e => {
      const workTimeRange = formatWorkTimeRange(e.start_date, e.end_date);
      return {
        title: e.company ?? '',
        value: `${e.position}</br><span class="subvalue">${workTimeRange}</span>`,
      };
    }) ?? []),
  ];

  return `
    <h3>Demography:</h3>
    ${buildTitleValueTable(generalData)}
    <br/>
    ${buildTitleValueTable(educationData)}
    <br/>
    ${buildTitleValueTable(possibleLocations)}
    <br/>
    ${!isEmpty(data.work) ? buildTitleValueTable(work) : ''}
    <hr/>
    `;
}



function buildResume(data: IResume, index: number): string {
  const tableData = [
    {
      title: 'Site',
      value: data.worksite,
    },
    {
      title: 'Name',
      value: data.name,
    },
    {
      title: 'Speciality',
      value: data.speciality,
    },
    {
      title: 'Age',
      value: data.age,
    },
    {
      title: 'City',
      value: data.city,
    },
    {
      title: 'Education',
      value: data.education_name,
    },
    data.created_date && {
      title: 'Date',
      value: moment(data.created_date).format('LLL'),
    },
    {
      title: 'User URL',
      value: data.user_url,
      valueTransformers: [formatLinks],
    },
    data.photo_url && {
      title: 'Photo',
      value: formatPhoto(data.photo_url),
    },
    {
      title: 'Experience',
    },
    ...(data.experience.map(e => ({
      title: e.position,
      value: `
          ${e.company ? e.company + '<br/>' : ''}
          ${e.desctiption ? e.desctiption + '<br/>' : ''}
          ${e.period ? e.period + '<br/>' : ''}
          ${`<span class="subvalue">${formatWorkTimeRange(e.startWork, e.endWork)}</span>`}
        `,
    })) ?? ''),
  ] as ITableItemsList;

  return `
    <h3>Resume #${index + 1}:</h3>
    ${buildTitleValueTable(tableData)}
    <hr/>
    `;
}



function buildMarketplaces(data: IMarketplace[]): string {
  const tableData = data.map(m => ({
    title: m.market_name ?? '',
    value: m.status ?? '',
  })) as ITableItemsList;

  return `
    <h3>Marketplaces:</h3>
    ${buildTitleValueTable(tableData)}
    <hr/>
    `;
}



function buildSubject(data: ISubject, resultIndex: number, index: number): string {
  const {
    account_info,
    conflictness,
    popularity,
    prorussian_rhetoric,
    prorussian_rhetoric_by_friends,
    sectarian,
    sectarian_by_friends,
    separatism,
    separatism_by_friends,
    lgbt_rhetoric,
    lgbt_rhetoric_by_friends,
    radical_rhetoric,
    radical_rhetoric_by_friends,
    military,
    military_by_friends,
    sn,
    users_interactions,
    ...rest
  } = data;

  const generalInfo = buildTableItemsFromStringObect(
    account_info ?? {},
    [formatLinks, formatPhoneNumbers],
    k => startCase(camelCase(k)),
  );

  const analysisResults = [
    {
      title: 'SN',
      value: sn ?? '',
    },
    {
      title: 'Popularity',
      value: buildTitleValueTable([
        {
          title: 'Status',
          value: popularity?.status ?? '',
        },
        {
          title: 'Comm. avg.',
          value: popularity?.comm_avg?.toString() ?? '',
        },
        {
          title: 'React. avg.',
          value: popularity?.react_avg?.toString() ?? '',
        },
      ]),
    },
    prorussian_rhetoric?.status && {
      title: 'Prorussian',
      value: prorussian_rhetoric?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    buildRhetoricByFriends(
      'Prorussian by friends',
      prorussian_rhetoric_by_friends,
      resultIndex, index,
      'prorussian_rhetoric_by_friends',
    ),
    sectarian?.status && {
      title: 'Sectarian',
      value: sectarian?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    buildRhetoricByFriends(
      'Sectarian by friends',
      sectarian_by_friends,
      resultIndex, index,
      'sectarian_by_friends',
    ),
    separatism?.status && {
      title: 'Separatism',
      value: separatism?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    buildRhetoricByFriends(
      'Separatism by friends',
      separatism_by_friends,
      resultIndex, index,
      'separatism_by_friends',
    ),
    lgbt_rhetoric?.status && {
      title: 'LGBT rhetoric',
      value: lgbt_rhetoric?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    buildRhetoricByFriends(
      'LGBT rhetoric by friends',
      lgbt_rhetoric_by_friends,
      resultIndex, index,
      'lgbt_rhetoric_by_friends',
    ),
    radical_rhetoric?.status && {
      title: 'Radical rhetoric',
      value: radical_rhetoric?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    radical_rhetoric_by_friends?.friends_count && {
      title: 'Radical rhetoric by friends',
      value: radical_rhetoric_by_friends?.friends_count ?? '',
    },
    buildRhetoricByFriends(
      'Radical rhetoric by friends',
      radical_rhetoric_by_friends,
      resultIndex, index,
      'radical_rhetoric_by_friends',
    ),
    military?.status && {
      title: 'Military rhetoric',
      value: military?.status ?? '',
      valueTransformers: [highlightLevel],
    },
    buildRhetoricByFriends(
      'Military rhetoric by friends',
      military_by_friends,
      resultIndex, index,
      'military_by_friends',
    ),
    {
      title: 'Users interactions',
      value: users_interactions?.status ?? '',
    },
    ...(Object.entries(rest).map(([key, value]) => ({
      title: startCase(camelCase(key)),
      value: (value as ISubjectStatusItem).status ?? '',
      valueTransformers: [formatLinks, formatPhoneNumbers],
    }))),
  ] as ITableItemsList;

  return `
    <h3>Subject #${index + 1}:</h3>
    ${buildTitleValueTable(generalInfo)}
    <br/>
    ${buildTitleValueTable(analysisResults)}
    <hr/>
    `;
}



function buildRhetoricByFriends(
  title: string,
  data: IRhetoricByFriends | undefined,
  itemIndex: number,
  subjectIndex: number,
  subjectKey: string,
): ITableItem | undefined {
  if (!data?.friends_count) return;

  return {
    title,
    value: (data?.friends_count ?? '') +
      (!isEmpty(data.comments) ? ` | ${buildCommentsLink(itemIndex, subjectIndex, subjectKey)}` : '') +
      (!isEmpty(data.fb_likes) ? ` | ${buildLikesLink(itemIndex, subjectIndex, subjectKey)}` : ''),
  };
}



function buildCommentsLink(
  itemIndex: number,
  subjectIndex: number,
  subjectKey: string,
): string {
  const path = `items[${itemIndex}].subjects[${subjectIndex}].${subjectKey}.comments`;
  return buildAppLink('Comments', 'comments', path);
}



function buildLikesLink(
  itemIndex: number,
  subjectIndex: number,
  subjectKey: string,
): string {
  const path = `items[${itemIndex}].subjects[${subjectIndex}].${subjectKey}.fb_likes`;
  return buildAppLink('Likes', 'likes', path);
}



function buildAppLink(title: string, mode: string, path: string) {
  if (Platform.OS === 'web') {
    const onClick = `parent.eventEmitter.emit('applink', {mode: '${mode}', path: '${path}'}); return false;`;
    return `<a onclick="${onClick}" href="#">${title}</a>`;
  }
  else {
    const link = `http://applink?mode=${mode}&path=${path}`;
    return `<a href="${link}">${title}</a>`;
  }
}
