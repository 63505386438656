import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Box, Heading, HStack, IHeadingProps } from 'native-base';
import { BackButton } from './BackButton';
import { CloseButton } from './CloseButton';
import { TextAction } from './TextAction';



export interface IScreenHeaderProps {
  title: string,
  leftStyle?: 'custom' | 'back' | 'close',
  rightStyle?: 'custom' | 'text' | 'close',
  rightText?: string,
  customLeft?: () => JSX.Element,
  customRight?: () => JSX.Element,
  onLeftPress?: () => void,
  onRightPress?: () => void,
  actionWidth?: number,
  transparent?: boolean,
  titleProps?: IHeadingProps,
}

export const ScreenHeader: React.FC<IScreenHeaderProps> = ({
  title,
  leftStyle = 'back',
  rightStyle = 'custom',
  rightText,
  customLeft,
  customRight,
  onLeftPress,
  onRightPress,
  actionWidth = 20,
  transparent = false,
  titleProps,
}) => {
  const navigation = useNavigation();

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  }, [navigation]);


  const leftComponent = useMemo(() => {
    if (leftStyle === 'back') {
      return <BackButton onPress={onLeftPress ?? goBack} />;
    }
    else if (leftStyle === 'close') {
      return <CloseButton onPress={onLeftPress ?? goBack} />;
    }
    else {
      return customLeft;
    }
  }, [customLeft, goBack, leftStyle, onLeftPress]);

  const rightComponent = useMemo(() => {
    if (rightStyle === 'close') {
      return <CloseButton onPress={onLeftPress ?? goBack} />;
    }
    if (rightStyle === 'text') {
      return (
        <TextAction onPress={onRightPress}>
          {rightText}
        </TextAction>);
    }
    else {
      return customRight;
    }
  }, [customRight, goBack, onLeftPress, onRightPress, rightStyle, rightText]);


  return (
    <Box
      bg={transparent ? 'transparent' : 'bgClear'}
      shadow={transparent ? undefined : '2'}
      safeAreaTop
      zIndex={10}
    >
      <HStack h="20" alignItems="center">
        <Box w={actionWidth} alignItems="center">
          {leftComponent}
        </Box>
        <Heading
          size="md"
          color="darkGrey"
          numberOfLines={1}
          flexShrink={1}
          flex={1}
          textAlign="center"
          {...titleProps}
        >
          {title}
        </Heading>
        <Box w={actionWidth} alignItems="center">
          {rightComponent}
        </Box>
      </HStack>
    </Box>
  );
};
