import { IFindPersonResult, IVerification, Request } from '@api';
import { IFindPersonInfo, IVerificationFilters, IVerifyPersonInfo } from '@global/types';
import { createModel } from '@rematch/core';
import { RootModel } from '.';



type VerifyState = {
  personInfo?: IVerifyPersonInfo
  verifications?: IVerification[]
  details?: IVerification

  findPersonInfo?: IFindPersonInfo
  findPersonResults?: IFindPersonResult[]
}

export const verification = createModel<RootModel>()({
  state: {
  } as VerifyState,
  reducers: {
    setPersonInfo(state: VerifyState, personInfo: IVerifyPersonInfo) {
      return {
        ...state,
        personInfo,
      };
    },
    setVerificationDetails(state: VerifyState, details: IVerification) {
      return {
        ...state,
        details,
      };
    },
    setFindPersonInfo(state: VerifyState, personInfo: IFindPersonInfo) {
      return {
        ...state,
        findPersonInfo: personInfo,
        findPersonResults: undefined,
      };
    },
    setFindPersonResults(state: VerifyState, results: IFindPersonResult[]) {
      return {
        ...state,
        findPersonResults: results,
      };
    },
    setVerifications(state: VerifyState, verifications: IVerification[]) {
      return {
        ...state,
        verifications,
      };
    },
  },
  effects: dispatch => ({
    async ping() {
      await Request.ping();
    },
    async verifyPerson(_, state) {
      if (!state.verification.personInfo) return;

      await Request.verifyPerson(state.verification.personInfo);
      await dispatch.verification.loadVerifications(undefined);
    },
    async loadVerifications(filters: IVerificationFilters | undefined, __) {
      const response = await Request.getVerifications(filters);
      dispatch.verification.setVerifications(response.data.message ?? []);
    },
    async loadVerificationDetails(verificationId: string) {
      const response = await Request.getVerificationDetails(verificationId);
      const verification = response.data.message?.length ? response.data.message[0] : undefined;
      if (!verification) throw 'Cannot load verification details';

      dispatch.verification.setVerificationDetails(verification);
    },
    async findPerson(_, state) {
      if (!state.verification.findPersonInfo) return;

      const result = await Request.findPerson(state.verification.findPersonInfo);
      if (result.data.message?.length) {
        dispatch.verification.setFindPersonResults(result.data?.message);
      }
    },
  }),
});
