import React, { useCallback, useState } from 'react';
import {
  Button,
  Heading,
  Text,
  VStack,
} from 'native-base';
import { FormPhoneInput, HTPadding, ScreenContainer, ScreenHeader } from '@components';
import { useWindowDimensions } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';
import { IPhoneNumber, joinPhoneNumber } from '@utils/phone';
import { isValidPhone } from '@utils/validation';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthNavigatorParamList } from '@navigation/AuthNavigator';
import { AppRoutes } from '@navigation/AppRoutes';
import { useDispatch } from 'react-redux';
import { handleAsyncOperation } from '../../utils/promise';
import { Dispatch } from '@store';



interface ILoginForm {
  phone: IPhoneNumber
}

type INavProps = StackScreenProps<AuthNavigatorParamList, AppRoutes.LogInPhone>;

export const LogInPhoneScreen: React.FC<INavProps> = ({
  navigation,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const windowHeight = useWindowDimensions().height;

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<ILoginForm>();

  const onContinue = useCallback(() => {
    const phoneNumber = joinPhoneNumber(form.getValues().phone, false);

    handleAsyncOperation(
      dispatch.auth.authCode(phoneNumber),
      setIsLoading,
      () => navigation.navigate(AppRoutes.LogInCode),
    );
  }, [dispatch.auth, form, navigation]);

  return (
    <ScreenContainer
      keyboardAvoiding
      paddingSides={HTPadding}
      loading={isLoading}
      header={
        <ScreenHeader
          transparent
          title=""
          leftStyle="custom"
        />
      }
    >
      <FormProvider {...form}>
        <VStack space="4" mt={`${windowHeight * 0.1}px`}>
          <Heading textAlign="center" mb="10">Log In</Heading>
          <Text
            fontSize="md"
            fontStyle="italic"
          >
            Step 1 of 2. Please enter your phone number
          </Text>

          <FormPhoneInput
            name="phone"
            rules={{
              required: true,
              validate: (value: IPhoneNumber) => isValidPhone(joinPhoneNumber(value, false)),
            }}
            placeholder="Phone number"
          />
          <Button
            children="Get auth code"
            onPress={form.handleSubmit(onContinue)}
          />
        </VStack>
      </FormProvider>
    </ScreenContainer>
  );
};
