import { ISuspiciousItem, ITimeline, ITimelineStep, IPerson } from './../api/models/entities';
import { ICheck, CheckStatus, RiskLevel } from '@api';
import { faker } from '@faker-js/faker';



export const mockPerson = (): IPerson => {
  return {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    photo: faker.image.avatar(),
    id: faker.random.alphaNumeric(10).toUpperCase(),
  };
};



// export const mockVerifications = (count = 10) => {
//   return new Array(count).fill(0)
//     .map(() => ({
//       id: faker.datatype.uuid(),
//       status: faker.random.arrayElement<VerificationStatus>([
//         'in-progress', 'failed', 'done',
//       ]),
//       person: mockPerson(),
//     }) as IVerification);
// };



export const mockTimeline = () => {
  const step = faker.datatype.number(2);

  const mockStep = () => ({
    person: mockPerson(),
    date: faker.date.past(1 / 365),
    finished: true,
  } as ITimelineStep);

  const timeline: ITimeline = {
    submitted: (step >= 0) ? mockStep() : undefined,
    processed: (step >= 1) ? mockStep() : undefined,
    completed: (step >= 2) ? mockStep() : undefined,
  };

  return timeline;
};



export const mockCheck = (title: string, shortTitle?: string) => {
  const status = faker.random.arrayElement<CheckStatus>([
    'in-queue', 'pending', 'complete', 'skipped',
  ]);

  const riskLevel = (status === 'pending' || status === 'complete') ?
    faker.datatype.number(3) as RiskLevel :
    undefined;

  const suspicious: ISuspiciousItem[] | undefined =
    (status === 'pending' || status === 'complete') ?
      [
        {
          title: 'Unverified identity',
          riskLevel: faker.datatype.number(3) as RiskLevel,
        },
        {
          title: 'Address mismatch',
          riskLevel: faker.datatype.number(3) as RiskLevel,
        },
        {
          title: 'Photo mismatch',
          riskLevel: faker.datatype.number(3) as RiskLevel,
        },
      ] :
      undefined;

  const timeline = mockTimeline();

  return {
    id: faker.datatype.uuid(),
    title,
    shortTitle,
    status,
    riskLevel,
    suspicious,
    timeline,
  } as ICheck;
};



export const mockVerificationRisks = () => {
  return {
    generalRiskCheck: mockCheck('All Factors'),
    checks: [
      mockCheck('Document ID verification', 'Document ID'),
      mockCheck('Residential History'),
      mockCheck('Criminal History'),
      mockCheck('Employment History'),
    ],
  };
};
