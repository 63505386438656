import { isEmpty } from 'lodash';
import { buildDetailsPage, buildTitleValueTable } from './helpers';
import { ILike, ITableItemsList } from './types';

export interface ILikesParameters {
  data: ILike[]
}



export function buildLikesPage({
  data,
}: ILikesParameters) {
  return buildDetailsPage(
    `<h2>=== Likes ===</h2>
    ${!isEmpty(data) ? data!.map((r) => buildLike(r)).join('') : ''}`
  );
}



function buildLike(data: ILike): string {
  const tableItems = [
    {
      title: 'Name',
      value: `<a href="${data.link}" target="_blank">${data.name}</a>`,
    },
    {
      title: 'Subscribtions',
      value: data.subscribtions,
    },
    {
      title: 'Subscribed friends',
      value: data.subscribtions_friends,
    },
  ] as ITableItemsList;


  return `${buildTitleValueTable(tableItems)}
  <hr/>`;
}

