import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { FindPersonScreen, LoadingScreen, VerifyPersonScreen } from '@screens';
import { MainTabsNavigator } from './MainTabsNavigator';
import { AppRoutes } from './AppRoutes';
import { AuthNavigator } from './AuthNavigator';
import { APP_TITLE } from '../global/constants';



const Stack = createStackNavigator<RootNavigatorParamList>();

export const RootNavigator: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}>
      <Stack.Screen
        name={AppRoutes.Loading}
        component={LoadingScreen}
      />
      <Stack.Screen
        name={AppRoutes.AuthNavigator}
        component={AuthNavigator}
      />
      <Stack.Screen
        name={AppRoutes.MainTabsNavigator}
        component={MainTabsNavigator}
      />
      <Stack.Screen
        options={{
          presentation: 'modal',
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
        }}
        name={AppRoutes.VerifyPersonModal}
        component={VerifyPersonScreen}
      />
      <Stack.Screen
        options={{
          presentation: 'modal',
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
        }}
        name={AppRoutes.FindPersonModal}
        component={FindPersonScreen}
      />
    </Stack.Navigator>
  );
};


export type RootNavigatorParamList = {
  [AppRoutes.Loading]: undefined,
  [AppRoutes.AuthNavigator]: undefined,
  [AppRoutes.MainTabsNavigator]: undefined,
  [AppRoutes.VerifyPersonModal]: undefined,
  [AppRoutes.FindPersonModal]: undefined,
}
