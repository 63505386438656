import { showMessage } from './alertHelper';
import { Error } from './error';


type CustomError = {
  originalMessage?: string,
  originalCode?: number,

  customTitle?: string,
  customMessage?: string,
}



class ErrorHandler {
  customErrors: CustomError[] = [
    // {
    //     originalCode: ERROR_NEED_RESET_SESSION,
    //     customTitle: 'Session expired',
    //     customMessage: 'Your session has expired. Please re-login',
    // },
  ];



  messageForError(error: unknown | Error | string): string {
    return (typeof error === 'string') ? error : (error as Error).message;
  }



  customErrorForError(error: unknown | Error | string): CustomError | undefined {
    const message = this.messageForError(error);

    let code;
    if (typeof error === 'object') {
      code = (error as Error)?.code;
    }


    for (let i = 0; i < this.customErrors.length; ++i) {
      const customError = this.customErrors[i];

      if (customError.originalCode !== undefined && customError.originalCode === code) {
        return customError;
      }

      if (customError.originalMessage !== undefined && message !== undefined &&
        message.toLowerCase().includes(customError.originalMessage.toLowerCase())) {
        return customError;
      }
    }
  }



  handleError(error: unknown | Error | string, onConfirm: (() => void) | undefined = undefined) {
    if (typeof error !== 'object' && typeof error !== 'string') {
      return;
    }

    let title = 'Error';
    let message = 'Unknown error';

    const customError = this.customErrorForError(error);

    if (customError) {
      // Suppress error
      if (customError.customTitle === undefined && customError.customMessage === undefined) {
        console.log('Suppressed error: ', error);
        if (onConfirm) {
          onConfirm();
        }
        return;
      }

      // Use custom message and title
      title = customError.customTitle || title;
      message = customError.customMessage || message;
    }
    else {
      message = this.messageForError(error) || message;
    }

    showMessage(title, message, onConfirm);
  }
}



let instance: ErrorHandler | undefined;
export default function errorHandler(): ErrorHandler {
  if (instance === undefined) {
    instance = new ErrorHandler();
  }

  return instance;
}
