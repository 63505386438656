import React, { useCallback, useState } from 'react';
import {
  Heading,
  ScrollView,
  VStack,
} from 'native-base';
import { HPadding, ScreenContainer, VerifyFooter, ValueReview } from '@components';
import { AppRoutes } from '@navigation/AppRoutes';
import { StackScreenProps } from '@react-navigation/stack';
import { VerifyPersonNavigatorParamsList } from '@navigation/VerifyPersonNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '@store';
import { joinPhoneNumber } from '@utils/phone';
import { IVerifyPersonInfo } from '@global/types';
import { handleAsyncOperation } from '@utils/promise';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { HomeTabNavigatorParamList } from '@navigation/HomeTabNavigator';



type INavProps = StackScreenProps<VerifyPersonNavigatorParamsList, AppRoutes.VerifyInputStep> &
  BottomTabScreenProps<HomeTabNavigatorParamList>;

export const VerifyReviewStep: React.FC<INavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();

  const personInfo = useSelector<RootState, IVerifyPersonInfo | undefined>(state => state.verification.personInfo);

  const [isLoading, setIsLoading] = useState(false);

  const onContinue = useCallback(() => {
    handleAsyncOperation(
      dispatch.verification.verifyPerson(undefined),
      setIsLoading,
      () => navigation.navigate(AppRoutes.VerificationsList),
    );
  }, [dispatch.verification, navigation]);

  return (
    <ScreenContainer
      paddingSides={HPadding}
      loading={isLoading}
    >
      <Heading size="lg" pt="2" pb="8">Step 2: Review Contact Info</Heading>

      <ScrollView flex="1" mb="4" showsVerticalScrollIndicator={false}>
        <VStack space="6">
          <ValueReview
            title="FIRST NAME"
            value={personInfo?.firstName}
          />

          <ValueReview
            title="LAST NAME"
            value={personInfo?.lastName}
          />

          <ValueReview
            title="SOCIAL URL"
            value={personInfo?.socialID ?? '-'}
          />

          <ValueReview
            title="PHONE NUMBER"
            value={personInfo?.phoneNumber ?
              joinPhoneNumber(personInfo?.phoneNumber ?? { number: '' }, true) :
              '-'
            }
          />

          {/* {personInfo?.email &&
            <ValueReview
              title="EMAIL"
              value={personInfo?.email}
            />
          } */}
        </VStack>
      </ScrollView>

      <VerifyFooter
        // forwardTitle="Looks Good, Continue →"
        forwardTitle="Start Verification Process →"
        backTitle="← Or make changes to contact info"
        onForwardPress={onContinue}
      />

    </ScreenContainer>
  );
};
