import React from 'react';



interface IProps {
  wrapper: (children: React.ReactNode) => React.ReactNode
  condition: boolean
}

export const ConditionalWrapper: React.FC<IProps> = ({ condition, wrapper, children }) => (
  <>
    {condition ? wrapper(children) : children}
  </>
);
