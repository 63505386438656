import { TabBar } from '@components';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { HomeTabNavigator } from './HomeTabNavigator';
import { AppRoutes } from './AppRoutes';
import { SettingsTabNavigator } from './SettingsTabNavigator';
import { APP_TITLE } from '@global/constants';



const Tabs = createBottomTabNavigator<MainTabsNavigatorParamsList>();

export const MainTabsNavigator: React.FC = () => {
  return (
    <Tabs.Navigator
      tabBar={(props) => <TabBar {...props} />}
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}
    >
      <Tabs.Screen
        name={AppRoutes.HomeTabNavigator}
        component={HomeTabNavigator}
      />
      <Tabs.Screen
        name={AppRoutes.SettingsTabNavigator}
        component={SettingsTabNavigator}
      />
    </Tabs.Navigator>
  );
};


export type MainTabsNavigatorParamsList = {
  [AppRoutes.HomeTabNavigator]: undefined,
  [AppRoutes.SettingsTabNavigator]: undefined,
}
