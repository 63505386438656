/* eslint-disable max-len */
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { SvgSingleColorProps, SvgSizeProps } from './props';



export const SearchIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 16,
  height = 16,
  color = '#000',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <Path
      d="M6.84236 13.684C8.36049 13.6837 9.83487 13.1755 11.0307 12.2403L14.7906 16L16 14.7907L12.2401 11.031C13.1758 9.83508 13.6844 8.36043 13.6847 6.84199C13.6847 3.06949 10.6151 0 6.84236 0C3.06965 0 0 3.06949 0 6.84199C0 10.6145 3.06965 13.684 6.84236 13.684ZM6.84236 1.7105C9.67253 1.7105 11.9741 4.01197 11.9741 6.84199C11.9741 9.67201 9.67253 11.9735 6.84236 11.9735C4.01219 11.9735 1.71059 9.67201 1.71059 6.84199C1.71059 4.01197 4.01219 1.7105 6.84236 1.7105Z"
      fill={color}
    />
  </Svg>;
};



export const ChevronRight: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 8,
  height = 14,
  color = '#2A2A2A',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 8 14" fill="none">
    <Path
      d="M1.33333 1.66663L6.66666 6.99996L1.33333 12.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>;
};



export const PlusButton: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 24,
  height = 24,
  color = '#fff',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <Path
      d="M0 4.33333C0 3.18406 0.456546 2.08186 1.2692 1.2692C2.08186 0.456546 3.18406 0 4.33333 0H19.6667C20.8159 0 21.9181 0.456546 22.7308 1.2692C23.5435 2.08186 24 3.18406 24 4.33333V19.6667C24 20.8159 23.5435 21.9181 22.7308 22.7308C21.9181 23.5435 20.8159 24 19.6667 24H4.33333C3.18406 24 2.08186 23.5435 1.2692 22.7308C0.456546 21.9181 0 20.8159 0 19.6667V4.33333ZM13 6.33333C13 6.06812 12.8946 5.81376 12.7071 5.62623C12.5196 5.43869 12.2652 5.33333 12 5.33333C11.7348 5.33333 11.4804 5.43869 11.2929 5.62623C11.1054 5.81376 11 6.06812 11 6.33333V11H6.33333C6.06812 11 5.81376 11.1054 5.62623 11.2929C5.43869 11.4804 5.33333 11.7348 5.33333 12C5.33333 12.2652 5.43869 12.5196 5.62623 12.7071C5.81376 12.8946 6.06812 13 6.33333 13H11V17.6667C11 17.9319 11.1054 18.1862 11.2929 18.3738C11.4804 18.5613 11.7348 18.6667 12 18.6667C12.2652 18.6667 12.5196 18.5613 12.7071 18.3738C12.8946 18.1862 13 17.9319 13 17.6667V13H17.6667C17.9319 13 18.1862 12.8946 18.3738 12.7071C18.5613 12.5196 18.6667 12.2652 18.6667 12C18.6667 11.7348 18.5613 11.4804 18.3738 11.2929C18.1862 11.1054 17.9319 11 17.6667 11H13V6.33333Z"
      fill={color}
    />
  </Svg>;
};



export const CopyIcon: React.FC<SvgSizeProps & SvgSingleColorProps> = ({
  width = 24,
  height = 24,
  color = '#000',
}) => {
  return <Svg width={width} height={height} viewBox="0 0 488 488" fill="none">
    <G>
      <Path
        d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124
			C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124
			c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"
        fill={color}
      />
      <Path
        d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227
			c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6
			V38.6C439.65,17.3,422.35,0,401.05,0z"
        fill={color}
      />
    </G>
  </Svg>;
};


