import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScreenContainer, ScreenHeader } from '@components';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeTabNavigatorParamList } from '@navigation/HomeTabNavigator';
import { AppRoutes } from '@navigation/AppRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '@store';
import { get, isEmpty } from 'lodash';
import { Center, Text } from 'native-base';
import WebView from 'react-native-webview';
import { OnShouldStartLoadWithRequest, ShouldStartLoadRequest } from 'react-native-webview/lib/WebViewTypes';
import { Linking, Platform } from 'react-native';
import { buildMainPage } from './utils/buildMainPage';
import { buildCommentsPage } from './utils/buildCommentsPage';
import { URL } from 'react-native-url-polyfill';
import { buildLikesPage } from './utils/buildLikesPage';
import { IComment, ILike, IVerificationResultData } from './utils/types';
import EventEmitter from 'events';



export type DetailsMode = 'main' | 'comments' | 'likes'



type INavProps = StackScreenProps<HomeTabNavigatorParamList, AppRoutes.VerificationDetails>;

export const VerificationDetailsScreen: React.FC<INavProps> = ({
  route,
  navigation,
}) => {
  const {
    verificationId,
    title,
    mode,
    dataPath,
  } = route.params;

  const [isLoading, setIsLoading] = useState(true);
  const verification = useSelector((state: RootState) => state.verification.details);

  const verificationResultsJsonString = useMemo(
    () => verification?.fields['Verification results'] as string,
    [verification],
  );

  const data: unknown = useMemo(() => {
    try {
      const verifData = JSON.parse(verificationResultsJsonString);
      if (dataPath) return get(verifData, dataPath);
      return verifData;
    }
    catch (e) {
      console.log(`Error: ${e}`);
    }
    return {};
  }, [verificationResultsJsonString, dataPath]);

  const detailsHtml = useMemo(() => {
    try {
      if (mode === 'main') {
        const searchData = JSON.parse(verification?.fields['Search data'] ?? '');
        return buildMainPage({
          data: data as IVerificationResultData,
          searchData,
          submittedAt: new Date(verification?.fields['Verification submitted timestamp'] ?? ''),
          status: verification?.fields['Verification status'] ?? '',
        });
      }
      else if (mode === 'comments') {
        return buildCommentsPage({
          data: data as IComment[],
        });
      }
      else if (mode === 'likes') {
        return buildLikesPage({
          data: data as ILike[],
        });
      }
    }
    catch (e) {
      console.log(`Error: ${e}`);
      return '';
    }

    return '';
  }, [data, verification?.fields, mode]);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.verification.loadVerificationDetails(verificationId)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationId]);

  // Applinks functionality on Web
  useEffect(() => {
    if (Platform.OS !== 'web') return;

    const { eventEmitter } = window as unknown as { eventEmitter: EventEmitter };
    const eventHandler = ({
      mode,
      path,
    }: {
      mode: DetailsMode,
      path: string,
    }) => {
      navigation.push(AppRoutes.VerificationDetails, {
        verificationId: verificationId,
        title: title,
        mode: mode as DetailsMode,
        dataPath: path,
      });
    };

    eventEmitter.addListener('applink', eventHandler);

    return () => {
      eventEmitter.removeListener('applink', eventHandler);
    };
  }, [navigation, title, verificationId]);

  const onSeeData = useCallback(() => {
    navigation.navigate(AppRoutes.JsonViewer, { jsonString: JSON.stringify(data) });
  }, [data, navigation]);



  const shouldLoad: OnShouldStartLoadWithRequest = useCallback((event: ShouldStartLoadRequest) => {
    const url = event.url;
    const urlObj = !isEmpty(url) ? new URL(url) : undefined;
    const isExternalLink = Platform.OS === 'ios' ? event.navigationType === 'click' : true;
    const isInternalLink = urlObj?.hostname === 'applink';

    if (isInternalLink) {
      const mode = urlObj?.searchParams.get('mode') ?? undefined;
      const dataPath = urlObj?.searchParams.get('path') ?? undefined;
      if (mode) {
        navigation.push(AppRoutes.VerificationDetails, {
          verificationId: verificationId,
          title: title,
          mode: mode as DetailsMode,
          dataPath,
        });
      }
      return false;
    }
    else if (isExternalLink && url) {
      Linking.canOpenURL(url).then(supported => {
        if (supported) {
          Linking.openURL(url);
        }
      });
      return false;
    }

    return true;
  }, [navigation, title, verificationId]);



  return (
    <ScreenContainer
      loading={isLoading}
      header={
        <ScreenHeader
          title={title}
          rightStyle={__DEV__ ? 'text' : undefined}
          rightText="See JSON"
          onRightPress={onSeeData}
        />
      }
    >

      {
        isEmpty(detailsHtml)
          ?
          <Center flex="1">
            <Text children="No verification details" />
          </Center>
          :
          <WebView
            source={{ html: detailsHtml ?? '' }}
            onShouldStartLoadWithRequest={shouldLoad}
          />
      }
    </ScreenContainer>
  );
};
