import { RadioButtonOn, RadioButtonOff } from '@assets';
import { Button, IButtonProps, Image } from 'native-base';
import React, { useCallback } from 'react';



interface IProps extends IButtonProps {
  title: string,
  value: string
  currentValue: string
  setCurrentValue: (value: string) => void
}

export const RadioButton: React.FC<IProps> = ({
  value,
  currentValue,
  setCurrentValue,
  title,
  ...props
}) => {
  const isSelected = value === currentValue;
  const onPress = useCallback(() => {
    setCurrentValue(value);
  }, [setCurrentValue, value]);

  return (

    <Button
      leftIcon={
        <Image
          key={isSelected ? '1' : '0'}
          source={isSelected ? RadioButtonOn : RadioButtonOff}
          w="5" h="5"
          alt={title}
        />
      }
      onPress={onPress}
      variant="radio"
      justifyContent="flex-start"
      p="0"
      h="8"
      _text={{
        fontSize: 'md',
        fontWeight: isSelected ? '700' : '400',
      }}
      _stack={{
        space: '2',
      }}
      {...props}
    >
      {title}
    </Button>

  );
};
