import React, { useCallback, useMemo } from 'react';
import { ScreenContainer, ScreenHeader } from '@components';
import { StackScreenProps } from '@react-navigation/stack';
import { AppRoutes } from '@navigation/AppRoutes';
import { HomeTabNavigatorParamList } from '@navigation/HomeTabNavigator';
import JSONTree from 'react-native-json-tree';



type INavProps = StackScreenProps<HomeTabNavigatorParamList, AppRoutes.JsonViewer>;

export const JsonViewerScreen: React.FC<INavProps> = ({
  route,
}) => {
  const {
    jsonString,
  } = route.params;

  const jsonData = useMemo(() => {
    try {
      return JSON.parse(jsonString);
    }
    catch (e) {
      console.log(e);
      return {
        status: 'JSON Parsing error',
      };
    }
  }, [jsonString]);

  const theme = useMemo(() => ({
    base00: '#2a2a2a',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  }), []);

  const shouldExpandNode = useCallback(() => true, []);

  return (
    <ScreenContainer
      scrollable
      header={
        <ScreenHeader title="RAW JSON" />
      }
    >

      <JSONTree
        data={jsonData}
        theme={theme}
        shouldExpandNode={shouldExpandNode}
        invertTheme
      />

    </ScreenContainer>
  );
};
