import React from 'react';
import { IStackProps, Text, VStack } from 'native-base';
import { ActivityIndicator } from 'react-native';



interface IProps extends IStackProps {
  label?: string
}

export const Loader: React.FC<IProps> = ({
  label = 'Loading...',
  ...props
}) => {
  return (
    <VStack alignItems="center" justifyContent="center" {...props}>
      <ActivityIndicator size="large" />
      <Text fontSize="xl" fontWeight="bold" mt="5">{label}</Text>
    </VStack>
  );
};
