import React, { useCallback, useState } from 'react';
import {
  Button,
  Heading,
  Text,
  VStack,
} from 'native-base';
import { FormTextInput, HTPadding, ScreenContainer, ScreenHeader } from '@components';
import { Pressable, useWindowDimensions } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthNavigatorParamList } from '@navigation/AuthNavigator';
import { AppRoutes } from '@navigation/AppRoutes';
import { RootNavigatorParamList } from '@navigation/RootNavigator';
import { useDispatch } from 'react-redux';
import { handleAsyncOperation } from '../../utils/promise';
import { Dispatch } from '@store';



interface ILoginForm {
  code: string
}

type NavProps =
  StackScreenProps<AuthNavigatorParamList, AppRoutes.LogInCode> &
  StackScreenProps<RootNavigatorParamList>;

export const LogInCodeScreen: React.FC<NavProps> = ({
  navigation,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const windowHeight = useWindowDimensions().height;

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<ILoginForm>();

  const onContinue = useCallback(() => {
    const code = form.getValues().code;

    handleAsyncOperation(
      dispatch.auth.verifyAuthCode(code),
      setIsLoading,
      () => navigation.replace(AppRoutes.MainTabsNavigator),
      {
        errorMessage: 'The code you have entered is incorrect, please try again',
      }
    );
  }, [dispatch.auth, form, navigation]);

  // const onSetupAuth = useCallback(() => {
  //   navigation.navigate(AppRoutes.AuthSetup);
  // }, [navigation]);

  return (
    <ScreenContainer
      keyboardAvoiding
      paddingSides={HTPadding}
      loading={isLoading}
      header={
        <ScreenHeader
          transparent
          title=""
          leftStyle="back"
        />
      }
    >
      <FormProvider {...form}>
        <VStack space="4" mt={`${windowHeight * 0.1}px`}>
          <Heading textAlign="center" mb="10">Log In</Heading>
          <Text
            fontSize="md"
            fontStyle="italic"
          >
            Step 2 of 2. We have sent you a one time code via SMS.{'\n'}
            Please enter this authorization code to continue
          </Text>

          <FormTextInput
            textAlign="center"
            name="code"
            rules={{ validate: (value: string) => value.length === 6 }}
            placeholder="Auth code"
            keyboardType="number-pad"
            maxLength={6}
          />
          <Button
            children="Log In"
            onPress={form.handleSubmit(onContinue)}
          />

          <Pressable onPress={() => navigation.goBack()}>
            <Text color="dark" textAlign="center" underline>
              Didn’t receive a code via SMS?{'\n'}send me another authorization code
            </Text>
          </Pressable>

          {/* <Text
            fontSize="lg"
            fontWeight="700"
            textAlign="center"
          >
            or
          </Text>
          <Button
            children="Setup 2-factor authentication"
            variant="secondary"
            onPress={onSetupAuth}
          /> */}
        </VStack>
      </FormProvider>
    </ScreenContainer>
  );
};
