import React, { useCallback } from 'react';
import { Box, Center, IBoxProps, KeyboardAvoidingView, ScrollView } from 'native-base';
import { ConditionalWrapper } from './ConditionalWrapper';
import { Platform } from 'react-native';
import { Loader } from './Loader';



type PaddingSide = 'left' | 'top' | 'right' | 'bottom'
export const HPadding: PaddingSide[] = ['left', 'right'];
export const VPadding: PaddingSide[] = ['top', 'bottom'];
export const AllPadding: PaddingSide[] = ['left', 'top', 'right', 'bottom'];
export const HTPadding: PaddingSide[] = ['left', 'top', 'right'];

export const DefaultSidePadding = 6;

type ScreenContainerProps = {
  paddingSides?: PaddingSide[],
  sidePadding?: IBoxProps['p'],
  scrollable?: boolean,
  keyboardAvoiding?: boolean,
  keyboardVerticalOffset?: number,
  header?: React.ReactNode,
  bodyProps?: IBoxProps,
  loading?: boolean,
} & IBoxProps

export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  paddingSides = [],
  sidePadding = DefaultSidePadding,
  scrollable,
  keyboardAvoiding,
  keyboardVerticalOffset,
  header,
  bodyProps,
  children,
  loading,
  ...props
}) => {
  const keyboardAvoidingWrapper = useCallback((children) => (
    <KeyboardAvoidingView
      flex="1"
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
    >
      {children}
    </KeyboardAvoidingView>
  ), [keyboardVerticalOffset]);

  const scrollableWrapper = useCallback((children) => (
    <ScrollView
      flex="1"
      bounces={!keyboardAvoiding}
      showsVerticalScrollIndicator={false}
      bg="bgSoft"
    >
      {children}
    </ScrollView>
  ), [keyboardAvoiding]);

  return (
    <Box
      flex="1"
      bg="bgSoft"
      {...props}>

      {header}

      <ConditionalWrapper
        condition={(keyboardAvoiding && !loading) ?? false}
        wrapper={keyboardAvoidingWrapper}
      >
        <ConditionalWrapper
          condition={((keyboardAvoiding || scrollable) && !loading) ?? false}
          wrapper={scrollableWrapper}
        >

          <Box
            flex="1"
            pt={paddingSides.includes('top') ? sidePadding : 0}
            pb={paddingSides.includes('bottom') ? sidePadding : 0}
            pl={paddingSides.includes('left') ? sidePadding : 0}
            pr={paddingSides.includes('right') ? sidePadding : 0}
            {...bodyProps}
          >
            {
              loading ?
                <Center flex="1">
                  <Loader />
                </Center> :
                children
            }
          </Box>

        </ConditionalWrapper>
      </ConditionalWrapper>

    </Box>
  );
};
