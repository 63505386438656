import { APP_TITLE } from '@global/constants';
import { createStackNavigator } from '@react-navigation/stack';
import { VerificationDetailsScreen, VerificationsListScreen, JsonViewerScreen } from '@screens';
import * as React from 'react';
import { DetailsMode } from 'src/screens/VerificationDetails';
import { AppRoutes } from './AppRoutes';



const Stack = createStackNavigator<HomeTabNavigatorParamList>();

export const HomeTabNavigator: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}>
      <Stack.Screen
        name={AppRoutes.VerificationsList}
        component={VerificationsListScreen}
      />
      <Stack.Screen
        name={AppRoutes.VerificationDetails}
        component={VerificationDetailsScreen}
      />
      <Stack.Screen
        name={AppRoutes.JsonViewer}
        component={JsonViewerScreen}
      />
    </Stack.Navigator>
  );
};


export type HomeTabNavigatorParamList = {
  [AppRoutes.VerificationsList]: undefined,
  [AppRoutes.VerificationDetails]: {
    verificationId: string
    title: string
    mode: DetailsMode
    dataPath?: string
  },
  [AppRoutes.JsonViewer]: { jsonString: string },
}
