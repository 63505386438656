import React, { useCallback } from 'react';
import { Box, HStack, IBoxProps, Image, Pressable, Text, VStack } from 'native-base';
import { IPerson, IVerification, VerificationStatus } from '@api';
import { VERIFICATION_STATUS_TITLES } from '@global/constants';
import { ChevronRight } from '@assets';
import { getPersonFullName } from '@utils/personUtils';
import { convertToCamelCase } from '@utils/helpers';
import { ActivityIndicator } from 'react-native';

interface IProps extends IBoxProps {
  item: IVerification
  onPress?: (item: IVerification) => void
}

export const VerificationItem: React.FC<IProps> = ({
  item,
  onPress,
  ...props
}) => {
  const { fields } = item;
  const person = convertToCamelCase(fields) as IPerson;

  const fullName = getPersonFullName(person);

  const status = item.fields['Verification status'];
  const isProcessing =
    status === VerificationStatus.New ||
    status === VerificationStatus.Assigned;
  const isFinished =
    status === VerificationStatus.Finished;

  const handlePress = useCallback(() => {
    onPress?.(item);
  }, [item, onPress]);

  return (
    <Pressable onPress={handlePress}>
      <HStack
        h="18"
        space="3"
        alignItems="center"
        {...props}
      >
        <Image
          source={{ uri: person.photo }}
          bg="extraLightGrey2"
          w="12"
          h="12"
          rounded="full"
          alt={fullName}
        />

        <VStack
          flex="1"
          justifyContent="center"
        >
          <Text
            numberOfLines={1}
            fontSize="md"
            fontWeight="700"
            children={fullName}
          />

          <Text
            numberOfLines={1}
            fontSize="xs"
            children={`ID #${person.id}`}
          />
        </VStack>

        <Text
          numberOfLines={1}
          fontSize="xs"
          underline
          fontWeight="700"
          children={
            VERIFICATION_STATUS_TITLES[status]?.short ?? status ?? 'Unknown'
          }
        />

        <Box w="5" alignItems="flex-end">
          {isProcessing && <ActivityIndicator size="small" />}
          {isFinished && <ChevronRight />}
        </Box>

      </HStack>
    </Pressable >
  );
};
