export enum AppRoutes {
  Loading = 'Loading',

  AuthNavigator = 'AuthNavigator',
  LogInPhone = 'LogInPhone',
  LogInCode = 'LogInCode',
  AuthSetup = 'AuthSetup',

  MainTabsNavigator = 'MainTabsNavigator',

  HomeTabNavigator = 'HomeTabNavigator',
  VerificationsList = 'VerificationsList',
  VerificationDetails = 'VerificationDetails',
  JsonViewer = 'JsonViewer',

  SettingsTabNavigator = 'SettingsTabNavigator',
  Settings = 'Settings',

  VerifyPersonModal = 'VerifyPersonModal',
  VerifyPersonNavigator = 'VerifyPersonNavigator',
  VerifyInputStep = 'VerifyInputStep',
  VerifyReviewStep = 'VerifyReviewStep',
  VerifyFinalizeStep = 'VerifyFinalizeStep',

  FindPersonModal = 'FindPersonModal',
  FindPersonNavigator = 'FindPersonNavigator',
  FindInputStep = 'FindInputStep',
  FindResultsStep = 'FindResultsStep',
}
