import { useNavigation } from '@react-navigation/native';
import { Button, VStack } from 'native-base';
import React from 'react';



interface IProps {
  forwardTitle: string,
  backTitle?: string,
  onForwardPress: () => void,
}

export const VerifyFooter: React.FC<IProps> = ({
  forwardTitle,
  backTitle,
  onForwardPress,
}) => {
  const navigation = useNavigation();

  return (
    <VStack h="36" space="2">
      <Button
        h="16"
        size="lg"
        onPress={onForwardPress}
      >
        {forwardTitle}
      </Button>

      {backTitle &&
        <Button
          onPress={() => navigation.goBack()}
          variant="link"
          _text={{ color: 'dark' }}
        >
          {backTitle}
        </Button>
      }
    </VStack>
  );
};
