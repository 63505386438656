import React, { useCallback } from 'react';
import {
  Box,
  Heading,
  VStack,
} from 'native-base';
import { ScreenContainer, FormTextInput, FormPhoneInput, VerifyFooter } from '@components';
import { AppRoutes } from '@navigation/AppRoutes';
import { StackScreenProps } from '@react-navigation/stack';
import { VerifyPersonNavigatorParamsList } from '@navigation/VerifyPersonNavigator';
import { FormProvider, useForm } from 'react-hook-form';
import { isValidPhone } from '@utils/validation';
import { IPhoneNumber, joinPhoneNumber } from '@utils/phone';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@store';
import { IVerifyPersonInfo } from '@global/types';
import { isEmpty } from 'lodash';
import { Alert } from '@utils/Alert';



type INavProps = StackScreenProps<VerifyPersonNavigatorParamsList, AppRoutes.VerifyInputStep>;

export const VerifyInputStep: React.FC<INavProps> = ({ navigation }) => {
  const dispatch = useDispatch<Dispatch>();

  // const [emailMode, setEmailMode] = useState(false);
  const form = useForm<IVerifyPersonInfo>();

  // const toggleEmailMode = useCallback(() => {
  //   setEmailMode(!emailMode);
  // }, [emailMode]);

  const onSubmit = useCallback(() => {
    // Prepare data
    const values = { ...form.getValues() };
    if (isEmpty(values.phoneNumber?.number)) {
      delete values.phoneNumber;
    }

    // Validate whole form data
    if (isEmpty(values.socialID) && isEmpty(values.phoneNumber)) {
      Alert.alert(
        'Verify',
        'Please provide social ID link or phone number to start verification',
      );
      return;
    }

    // Store data and navigate to review
    dispatch.verification.setPersonInfo(values);
    navigation.navigate(AppRoutes.VerifyReviewStep);
  }, [dispatch.verification, form, navigation]);

  return (
    <Box flex="1" bg="bgSoft" px="6">
      <ScreenContainer
        keyboardAvoiding
        keyboardVerticalOffset={100}
      >
        <Heading size="lg" pt="2" pb="8">Step 1: Enter Contact Info</Heading>

        <FormProvider {...form}>
          <VStack space="6" mb="3">
            <FormTextInput
              name="firstName"
              rules={{ required: true }}
              placeholder="First and middle name"
            />

            <FormTextInput
              name="lastName"
              rules={{ required: true }}
              placeholder="Last name"
            />

            <FormTextInput
              name="socialID"
              // rules={{ required: true }}
              placeholder="Social ID link (Facebook, Twitter, Linkedin, etc)"
              autoCapitalize="none"
            />

            {/* {emailMode ?
              <FormTextInput
                name="email"
                rules={{
                  required: true,
                  validate: (value) => isValidEmail(value),
                }}
                placeholder="Email"
                keyboardType="email-address"
                autoCapitalize="none"
              />
              : */}
            <FormPhoneInput
              name="phoneNumber"
              rules={{
                validate: (value: IPhoneNumber) => isEmpty(value.number) || isValidPhone(joinPhoneNumber(value, false)),
              }}
              placeholder="Phone number"
            />
            {/* } */}
          </VStack>
        </FormProvider>

        {/* <Button variant="link" alignSelf="center" onPress={toggleEmailMode}>
          {emailMode ? 'Use phone number instead' : 'Use email address instead'}
        </Button> */}
      </ScreenContainer>

      <VerifyFooter
        forwardTitle="Review Contact Information →"
        onForwardPress={form.handleSubmit(onSubmit)}
      />
    </Box>
  );
};
