import React, { useCallback, useMemo } from 'react';
import { Box, Divider, Factory, IBoxProps, useToken } from 'native-base';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { StyleSheet, ViewProps } from 'react-native';



interface ITabViewTab {
  key: string
  title: string
  component: React.FC
}

interface IProps extends ViewProps {
  tabs: ITabViewTab[]
  indicatorColor?: string,
  dividerColor?: IBoxProps['bg'],
  activeLabelColor?: string,
  inactiveLabelColor?: string,
  tabsContainerProps?: IBoxProps,
}

const HVTabView: React.FC<IProps> = ({
  tabs,
  indicatorColor = 'dark',
  dividerColor = 'extraLightGrey',
  activeLabelColor = 'dark',
  inactiveLabelColor = 'lightGrey',
  tabsContainerProps,
  ...props
}) => {
  const [
    iColor,
    aLabelColor,
    iaLabelColor,
  ] = useToken('colors', [
    indicatorColor,
    activeLabelColor,
    inactiveLabelColor,
  ]);

  const [index, setIndex] = React.useState(0);

  const routes = useMemo(() => tabs.map(t => ({
    key: t.key,
    title: t.title,
  })), [tabs]);

  const renderScene = useMemo(() => SceneMap(
    tabs.reduce((prev, item) => ({
      ...prev,
      [item.key]: item.component,
    }), {})
  ), [tabs]);

  const renderTabBar = useCallback((props) => (
    <Box {...tabsContainerProps}>
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: iColor }}
        style={styles.tabBarStyle}
        activeColor={aLabelColor}
        inactiveColor={iaLabelColor}
        labelStyle={[styles.labelStyle]}
      />
      <Divider bg={dividerColor} h="2px" position="absolute" bottom="0" />
    </Box>
  ), [tabsContainerProps, aLabelColor, dividerColor, iColor, iaLabelColor]);

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  tabBarStyle: {
    backgroundColor: 'transparent',
  },
  labelStyle: {
    textTransform: 'none',
    fontFamily: 'PTSans-Bold',
    fontSize: 16,
  },
});

const NBTabView = Factory(HVTabView);
export { NBTabView as HVTabView };
