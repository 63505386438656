import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { IInputProps, Input, InputGroup, InputLeftAddon, Pressable } from 'native-base';
import { TextInput } from '../TextInput';
import { IInputGroupProps } from 'native-base/lib/typescript/components/primitives/Input/types';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { IPhoneNumber } from '@utils/phone';
import { CountryCodeModal } from './CountryCodeModal';
import { ICountryInfo } from '@utils/countries-emoji';
import AsyncStorage from '@react-native-async-storage/async-storage';



const LAST_PHONE_CODE_KEY = 'lastPhoneCode';



export interface IPhoneInputProps extends Omit<IInputProps, 'value' | 'onChange'> {
  groupProps?: IInputGroupProps
  invalid?: boolean
  value?: IPhoneNumber
  defaultCode?: string
  onChange?: (value: IPhoneNumber) => void
}

export const PhoneInput = forwardRef<typeof Input, IPhoneInputProps>(({
  groupProps,
  invalid,
  onChange,
  value,
  defaultCode = '1',
  ...props
}, ref) => {
  const borderColor = invalid ? 'red.400' : undefined;

  const [code, setCode] = useState(value?.code);
  const [number, setNumber] = useState(value?.number);
  const [codeSelecting, setCodeSelecting] = useState(false);

  const onChangeNumber = useCallback((e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setNumber(e.nativeEvent.text);
  }, []);

  const onOpenCodeModal = useCallback(() => {
    setCodeSelecting(true);
  }, []);

  const onCloseCodeModal = useCallback(() => {
    setCodeSelecting(false);
  }, []);

  const onSelectCountry = useCallback((country: ICountryInfo) => {
    const c = (country.callingCode[0]);
    setCode(c);
    AsyncStorage.setItem(LAST_PHONE_CODE_KEY, c);
    setCodeSelecting(false);
  }, []);

  useEffect(() => {
    AsyncStorage.getItem(LAST_PHONE_CODE_KEY)
      .then((c) => {
        if (!value?.code) setCode(c ?? defaultCode);
      })
      .catch(() => {
        if (!value?.code) setCode(defaultCode);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCode(value?.code);
  }, [value?.code]);

  useEffect(() => {
    setNumber(value?.number);
  }, [value?.number]);

  useEffect(() => {
    onChange?.({
      code: code ?? '',
      number: number ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, number]);

  return (
    <InputGroup {...groupProps}>
      <Pressable onPress={onOpenCodeModal}>
        <InputLeftAddon
          flex="1"
          borderColor={borderColor}
          children={`+${code}`} />
      </Pressable>

      <TextInput
        ref={ref}
        flex="1"
        keyboardType="number-pad"
        maxLength={11}
        borderColor={borderColor}
        value={number}
        onChange={onChangeNumber}
        {...props}
      />

      <CountryCodeModal
        isOpen={codeSelecting}
        onClose={onCloseCodeModal}
        onSelect={onSelectCountry}
      />
    </InputGroup>
  );
});
