import React from 'react';
import { PageIndicator, ScreenContainer, ScreenHeader } from '@components';
import { VerifyPersonNavigator } from '@navigation/VerifyPersonNavigator';
import { HStack, Text } from 'native-base';
import { useState } from 'react';



export const VerifyPersonScreen: React.FC = () => {
  const stepsCount = 2;
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <ScreenContainer
      safeAreaBottom
      header={
        <ScreenHeader
          transparent
          title="VERIFY NEW PERSON"
          leftStyle="close"
          titleProps={{ size: 'sm' }}
        />
      }
    >
      <VerifyPersonNavigator onIndexChange={setCurrentStep} />

      <HStack justifyContent="center" space="4">
        <Text fontWeight="700" color="darkGrey" fontSize="14">
          STEP {currentStep + 1} OF {stepsCount}
        </Text>
        <PageIndicator count={stepsCount} selectedIndex={currentStep} />
      </HStack>
    </ScreenContainer>
  );
};
