import 'react-native-gesture-handler';
import { registerRootComponent } from 'expo';
import 'react-native-gesture-handler';
import { LogBox, Platform } from 'react-native';
import { YellowBox } from 'react-native-web';
import EventEmitter from 'events';
import App from './App';



console.log({ platform: Platform.OS });
if (Platform.OS === 'web') {
  YellowBox.ignoreWarnings(['Warning: ...']);
}
else {
  LogBox.ignoreLogs(['Warning: ...']);
  LogBox.ignoreAllLogs();
}

// Applinks functionality on Web
if (Platform.OS === 'web') {
  window.eventEmitter = new EventEmitter();
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
