import * as React from 'react';
import { Provider } from 'react-redux';
import { models, RootModel } from './models';
import persistPlugin from '@rematch/persist';
import secureStorage from '@utils/secureStorage';
import { init } from '@rematch/core';



const persistConfig = {
  key: 'root',
  storage: secureStorage(),
  whitelist: ['auth'],
};

export const store = init<RootModel>({
  models,
  plugins: [persistPlugin(persistConfig)],
  redux: {
    rootReducers: {
      RESET: () => undefined,
    },
  },
});

export const RootProvider: React.FC = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
);
