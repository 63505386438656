import { APP_TITLE } from '@global/constants';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { AuthSetupScreen, LogInCodeScreen, LogInPhoneScreen } from '@screens';
import * as React from 'react';
import { AppRoutes } from './AppRoutes';



const Stack = createStackNavigator<AuthNavigatorParamList>();

export const AuthNavigator: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: APP_TITLE,
      }}>
      <Stack.Screen
        name={AppRoutes.LogInPhone}
        component={LogInPhoneScreen}
      />
      <Stack.Screen
        name={AppRoutes.LogInCode}
        component={LogInCodeScreen}
      />
      <Stack.Screen
        name={AppRoutes.AuthSetup}
        component={AuthSetupScreen}
        options={{
          presentation: 'modal',
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
        }}
      />
    </Stack.Navigator>
  );
};


export type AuthNavigatorParamList = {
  [AppRoutes.LogInPhone]: undefined,
  [AppRoutes.LogInCode]: undefined,
  [AppRoutes.AuthSetup]: undefined,
}
