import { Button, IButtonProps } from 'native-base';
import React from 'react';



export const TextAction: React.FC<IButtonProps> = (props) => {
  return (
    <Button variant="plain" {...props} />
  );
};
