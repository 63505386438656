import { BackIcon } from '@assets';
import { Button, IButtonProps } from 'native-base';
import React from 'react';



export const BackButton: React.FC<IButtonProps> = (props) => {
  return (
    <Button variant="plain" {...props} leftIcon={<BackIcon />}>Back</Button>
  );
};
