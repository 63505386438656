import { CheckmarkOff, CheckmarkOn } from '@assets';
import { HStack, IStackProps, Text } from 'native-base';
import React from 'react';



interface IProps extends IStackProps {
  title: string
  isOn: boolean
}

export const CheckOption: React.FC<IProps> = ({
  title,
  isOn,
  ...props
}) => {
  return (
    <HStack space="2" h="8" alignItems="center" {...props}>
      {isOn ? <CheckmarkOn /> : <CheckmarkOff />}
      <Text fontSize="md">{title}</Text>
    </HStack>
  );
};
