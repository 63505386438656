import { SearchIcon } from '@assets';
import { Box } from 'native-base';
import React from 'react';
import { ITextInputProps, TextInput } from './TextInput';



export const SearchInput: React.FC<ITextInputProps> = (props) => {
  return (
    <TextInput
      rightElement={<Box mr="4"><SearchIcon /></Box>}
      {...props}
    />
  );
};
